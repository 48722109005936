// import FarmContract from './FarmContract.json';
import FarmContractVesting from './FarmContractVesting.json';
import FarmContractForSingleToken from './FarmContractForSingleToken.json';
import LIQSingleToken from './LIQSingleToken.json';
import LPToken from './LPToken.json';
import NFT_HelmetContract from './NFT_HelmetContract.json';
import NFT_HelmetStakingContract from './NFT_HelmetStakingContract.json';
import BNB_ABI from './BNB_ABI.json';
import LIQ_ABI from './LIQ_ABI.json';
import LIQ_HOLDING_ABI from './LIQHolding.json';
import Crona_LP_Token from './Crona_LP_Token.json';
import SwapBscABI from './SwapBSC_ABI.json';
import SwapCronosABI from './SwapCRONOS_ABI.json';
import SwapMaticABI from './SwapMATIC_ABI.json';
import SwapEthABI from './SwapETH_ABI.json';
import BuyLiqABI from './BUY_LIQ_ABI.json';
import BuyLiqCronosABI from './BUY_LIQ_CRONOS_ABI.json';
import CoinmarketABI from './CM_ABI.json';

export const COINMARKET_CONTRACT = {
    address: '0x1730C92D7fc02fccB178AD183F9d91E367642E28',
    abi: CoinmarketABI
}

export const CONTRACT_FOR_BNB = {
    address: '0x58f876857a02d6762e0101bb5c46a8c1ed44dc16',
    abi: BNB_ABI
}

export const LIQ_ETH_CONTRACT = {
    address: '0x13c869bb9519353643269846ad6f96F59c1F4F3e',
    abi: Crona_LP_Token
}

export const LIQ_BNB_CONTRACT = {
    address: '0x1F04Bf1938a36fF0BD6f811Ee5EF6822Ecb6ED49',
    abi: LIQ_ABI
}

export const LIQ_CRO_CONTRACT = {
    address: '0x3295007761C290741B6b363b86dF9ba3467F0754',
    abi: Crona_LP_Token
}

export const LIQ_MATIC_CONTRACT = {
    address: '0x2F5a5E6FF15B9B342e6292e2F179e7199f88922F',
    abi: Crona_LP_Token
}

export const LIQ_HOLDING_CONTRACTS = [
    {
        netId: 56,
        address: '0x56477d0d4718eB5eEFBFfc083acde179146c68F3',
        abi: LIQ_HOLDING_ABI
    },
    {
        netId: 25,
        address: '0x62A49fd455b71BFFF8801568D6D127a11BEc352e',
        abi: LIQ_HOLDING_ABI
    }
];

export const SUPPORTED_NET = [1, 56, 97, 25, 137];

export const RPC = {
    1: {url: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161', name: 'ethereum mainnet', explorerUrl: 'https://etherscan.io/'},
    56: {url: 'https://bsc-dataseed1.binance.org', name: 'bscscan', explorerUrl: 'https://bscscan.com/address/'},
    97: {url: 'https://data-seed-prebsc-1-s1.binance.org:8545', name: 'test', explorerUrl: 'https://testnet.bscscan.com/address/'},
    25: {url: 'https://evm-cronos.crypto.org', name: 'cronos', explorerUrl: 'https://cronos.crypto.org/explorer/address/'},
    137: {url: 'https://polygon-rpc.com', name: 'polygon', explorerUrl: 'https://polygonscan.com/address/'}
}

const ethNet = [{
    chainId: '0x1',
    chainName: 'Ethereum Mainnet',
    nativeCurrency: {
        name: 'ETH',
        symbol: 'ETH',
        decimals: 18
    },
    rpcUrls: ['https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
    blockExplorerUrls: ['https://etherscan.io'],
}];
const bscNet = [{
    chainId: '0x38',
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
        name: 'BNB',
        symbol: 'BNB',
        decimals: 18
    },
    rpcUrls: ['https://bsc-dataseed1.binance.org/'],
    blockExplorerUrls: ['https://bscscan.com/'],
}];
const cronosNet = [{
    chainId: '0x19',
    chainName: 'Cronos',
    nativeCurrency: {
        name: 'CRO',
        symbol: 'CRO',
        // decimals: 18
    },
    rpcUrls: ['https://evm-cronos.crypto.org/'],
    blockExplorerUrls: ['https://cronos.crypto.org/explorer/'],
}];
const polygonNet = [{
    chainId: '137',
    chainName: 'Polygon',
    nativeCurrency: {
        name: 'MATIC',
        symbol: 'MATIC',
        decimals: 18
    },
    rpcUrls: ['https://polygon-rpc.com/'],
    blockExplorerUrls: ['https://polygonscan.com/address/'],
}];
export const NETWORK_INFO = {
    1: ethNet,
    56: bscNet,
    25: cronosNet,
    137: polygonNet,
};

export const ETH_CONTRACT_LIST = [
    {
        address: '0xd27fab2323edb75af6f4348e3745247e77690e53',
        lpSwapToken: '0x13c869bb9519353643269846ad6f96F59c1F4F3e',
        rewardToken: '0x5F69b7Ab8F7cAb199a310Fd5A27B43Fef44ddcC0',
        abi: FarmContractVesting,
        tokenAbi: LPToken,
        vesting: 1,
        type: 'LP',
        name: 'LIQ - ETH',
        title: 'UniSwap',
        contractLink: 'https://etherscan.io/address/0xd27fab2323edb75af6f4348e3745247e77690e53',
        link: 'https://app.uniswap.org/#/add/v2/ETH/0x5F69b7Ab8F7cAb199a310Fd5A27B43Fef44ddcC0?chain=mainnet',
        linkName: 'LIQ-ETH LP'
    },
];

export const BSC_CONTRACT_LIST = [
    {
        address: '0x787fa31b4d75E45c1B83649510a588D580Eb4f57',
        lpSwapToken: '0x99e338d7524887e93e6f24582aab9ee9845e1353',
        rewardToken: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
        abi: FarmContractVesting,
        tokenAbi: LPToken,
        vesting: null,
        type: 'LP',
        name: 'LIQ - BNB',
        title: 'Biswap',
        contractLink: 'https://bscscan.com/address/0x787fa31b4d75E45c1B83649510a588D580Eb4f57',
        link: 'https://exchange.biswap.org/#/add/ETH/',
        linkName: 'LIQ-BNB LP'
    },
    {
        address: '0xb944b748A35B6dFFDd924bffD85910F968943a72',
        lpSwapToken: '0x1f04bf1938a36ff0bd6f811ee5ef6822ecb6ed49',
        rewardToken: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
        abi: FarmContractVesting,
        tokenAbi: LPToken,
        vesting: 1, // month
        type: 'LP',
        name: 'LIQ - BNB',
        title: 'PancakeSwap',
        contractLink: 'https://bscscan.com/address/0xb944b748A35B6dFFDd924bffD85910F968943a72',
        link: 'https://pancakeswap.finance/add/BNB/',
        linkName: 'LIQ-BNB LP'
    },
    {
        address: '0x8B79d987363a3e1EDA2A2eEBb38C242311C6c008',
        lpSwapToken: '0x1f04bf1938a36ff0bd6f811ee5ef6822ecb6ed49',
        rewardToken: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
        abi: FarmContractVesting,
        tokenAbi: LPToken,
        vesting: 1, // month
        type: 'LP',
        name: 'LIQ - BNB',
        title: 'PancakeSwap',
        contractLink: 'https://bscscan.com/address/0x8B79d987363a3e1EDA2A2eEBb38C242311C6c008',
        link: 'https://pancakeswap.finance/add/BNB/',
        linkName: 'LIQ-BNB LP'
    },
    {
        address: '0x7A0D4A0D88994E73a9eDCd79Ecad9097aCb1d937',
        lpSwapToken: '0x759584fe196fb2e3f5bf2eff8c2747741d5ecf59',
        rewardToken: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
        abi: FarmContractVesting,
        tokenAbi: LPToken,
        vesting: 1, // month
        type: 'LP',
        name: 'LIQ - BUSD',
        title: 'ApeSwap',
        contractLink: 'https://bscscan.com/address/0x7A0D4A0D88994E73a9eDCd79Ecad9097aCb1d937',
        link: 'https://app.apeswap.finance/add/',
        linkName: 'LIQ-BUSD LP'
    },
    {
        address: '0xB67640F6D742d35650F95E4C0294670E8824adaa',
        lpSwapToken: '0x759584fe196fb2e3f5bf2eff8c2747741d5ecf59',
        rewardToken: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
        abi: FarmContractVesting,
        tokenAbi: LPToken,
        vesting: 1, // month
        type: 'LP',
        name: 'LIQ - BUSD',
        title: 'ApeSwap',
        contractLink: 'https://bscscan.com/address/0xB67640F6D742d35650F95E4C0294670E8824adaa',
        link: 'https://app.apeswap.finance/add/',
        linkName: 'LIQ-BUSD LP'
    },
    {
        address: '0x19646186D7364b8c1Fb60f9772d2B186EA68983D',
        lpSwapToken: '0x99e338d7524887e93e6f24582aab9ee9845e1353',
        rewardToken: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
        abi: FarmContractVesting,
        tokenAbi: LPToken,
        vesting: 3, // month
        type: 'LP',
        name: 'LIQ - BNB',
        title: 'Biswap',
        contractLink: 'https://bscscan.com/address/0x19646186D7364b8c1Fb60f9772d2B186EA68983D',
        link: 'https://exchange.biswap.org/#/add/ETH/',
        linkName: 'LIQ-BNB LP'
    },
    {
        address: '0xbeBCd3aD501Fc425a71CDC7593CEDeA324176E92',
        stakedToken: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
        rewardToken: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
        abi: FarmContractForSingleToken,
        tokenAbi: LIQSingleToken,
        vesting: 1, // month
        type: 'LIQ',
        name: 'LIQ - Single Token',
        multiplier: 10,
        contractLink: 'https://bscscan.com/address/0xbeBCd3aD501Fc425a71CDC7593CEDeA324176E92',
        link: 'https://farm.liquidus.finance/buyliq',
        linkName: 'LIQ'
    },
    {
        address: '0x5CcD597728b1F088bFB749D9a9798ED0C6e2211C',
        stakedToken: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
        rewardToken: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
        abi: FarmContractForSingleToken,
        tokenAbi: LIQSingleToken,
        vesting: 3, // month
        type: 'LIQ',
        name: 'LIQ - Single Token',
        multiplier: 15,
        contractLink: 'https://bscscan.com/address/0x5CcD597728b1F088bFB749D9a9798ED0C6e2211C',
        link: 'https://farm.liquidus.finance/buyliq',
        linkName: 'LIQ'
    },
    {
        address: '0x03c5D2Cf0b79822eDB2B60d9F5aF290Facf190DD',
        stakedToken: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
        rewardToken: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
        abi: FarmContractForSingleToken,
        tokenAbi: LIQSingleToken,
        vesting: 3, // month
        type: 'LIQ',
        name: 'LIQ - Single Token',
        multiplier: 15,
        contractLink: 'https://bscscan.com/address/0x03c5D2Cf0b79822eDB2B60d9F5aF290Facf190DD',
        link: 'https://farm.liquidus.finance/buyliq',
        linkName: 'LIQ'
    },
    {
        address: '0xAb97B17B1547A8776299D4934fF5C8c7b247db6A',
        stakedToken: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
        rewardToken: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
        abi: FarmContractForSingleToken,
        tokenAbi: LIQSingleToken,
        vesting: 6, // month
        type: 'LIQ',
        name: 'LIQ - Single Token',
        multiplier: 15,
        contractLink: 'https://bscscan.com/address/0xAb97B17B1547A8776299D4934fF5C8c7b247db6A',
        link: 'https://farm.liquidus.finance/buyliq',
        linkName: 'LIQ'
    },
    {
        address: '0x7b00f1084B0C70089e569c41451E34E477ecb184',
        stakedToken: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
        rewardToken: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
        abi: FarmContractForSingleToken,
        tokenAbi: LIQSingleToken,
        vesting: 6, // month
        type: 'LIQ',
        name: 'LIQ - Single Token',
        multiplier: 15,
        contractLink: 'https://bscscan.com/address/0x7b00f1084B0C70089e569c41451E34E477ecb184',
        link: 'https://farm.liquidus.finance/buyliq',
        linkName: 'LIQ'
    },
    {
        address: '0xc6AEd0e5B81383Fd8537f4f805492732BDf8efC0',
        stakedToken: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
        rewardToken: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
        abi: FarmContractForSingleToken,
        tokenAbi: LIQSingleToken,
        vesting: 12, // month
        type: 'LIQ',
        name: 'LIQ - Single Token',
        multiplier: 25,
        contractLink: 'https://bscscan.com/address/0xc6AEd0e5B81383Fd8537f4f805492732BDf8efC0',
        link: 'https://farm.liquidus.finance/buyliq',
        linkName: 'LIQ'
    },
    {
        address: '0x89c0e71A3109311aD82ea786a76c074Ef82e91d7',
        stakedToken: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
        rewardToken: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
        abi: FarmContractForSingleToken,
        tokenAbi: LIQSingleToken,
        vesting: 12, // month
        type: 'LIQ',
        name: 'LIQ - Single Token',
        multiplier: 25,
        contractLink: 'https://bscscan.com/address/0x89c0e71A3109311aD82ea786a76c074Ef82e91d7',
        link: 'https://farm.liquidus.finance/buyliq',
        linkName: 'LIQ'
    },
];

export const CRONOS_CONTRACT_LIST = [
    {
        address: '0x1c7fDE0a9619bC81b23cAEF6992288BA5547a34F',
        lpSwapToken: '0x3295007761C290741B6b363b86dF9ba3467F0754',
        rewardToken: '0xABd380327Fe66724FFDa91A87c772FB8D00bE488',
        abi: FarmContractVesting,
        tokenAbi: Crona_LP_Token,
        vesting: null,
        type: 'LP',
        name: 'LIQ - CRO',
        title: 'CronaSwap',
        contractLink: 'https://cronoscan.com/address/0x1c7fDE0a9619bC81b23cAEF6992288BA5547a34F#code',
        link: 'https://app.cronaswap.org/add/CRO/',
        linkName: 'LIQ-CRO LP'
    },
    {
        address: '0xc7981767f644C7F8e483DAbDc413e8a371b83079',
        stakedToken: '0xABd380327Fe66724FFDa91A87c772FB8D00bE488',
        rewardToken: '0xABd380327Fe66724FFDa91A87c772FB8D00bE488',
        abi: FarmContractForSingleToken,
        tokenAbi: Crona_LP_Token,
        vesting: 3, // month
        type: 'LIQ',
        name: 'LIQ - Single Token',
        multiplier: 15,
        contractLink: 'https://cronoscan.com/address/0xc7981767f644C7F8e483DAbDc413e8a371b83079#code',
        link: 'https://farm.liquidus.finance/buyliq',
        linkName: 'LIQ'
    },
    {
        address: '0xB67640F6D742d35650F95E4C0294670E8824adaa',
        stakedToken: '0xABd380327Fe66724FFDa91A87c772FB8D00bE488',
        rewardToken: '0xABd380327Fe66724FFDa91A87c772FB8D00bE488',
        abi: FarmContractForSingleToken,
        tokenAbi: Crona_LP_Token,
        vesting: 3, // month
        type: 'LIQ',
        name: 'LIQ - Single Token',
        multiplier: 15,
        contractLink: 'https://cronoscan.com/address/0xB67640F6D742d35650F95E4C0294670E8824adaa#code',
        link: 'https://farm.liquidus.finance/buyliq',
        linkName: 'LIQ'
    }
];

export const MATIC_CONTRACT_LIST = [
    {
        address: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
        lpSwapToken: '0x2F5a5E6FF15B9B342e6292e2F179e7199f88922F',
        rewardToken: '0x4036f3d9c45a20f44f0b8B85dD6CA33005fF9654',
        abi: FarmContractVesting,
        tokenAbi: LPToken,
        vesting: 1,
        type: 'LP',
        name: 'LIQ - MATIC',
        title: 'QuickSwap V2',
        contractLink: 'https://polygonscan.com/address/0xc7981767f644c7f8e483dabdc413e8a371b83079',
        link: 'https://quickswap.exchange/#/add/0x4036f3d9c45a20f44f0b8B85dD6CA33005fF9654/ETH/v2',
        linkName: 'LIQ-MATIC LP'
    },
];

export const TOKEN_ABI = {
    "LP": LPToken,
    "LIQ": LIQSingleToken,
}

// Test net
// export const NFT_LIST = [
//     {
//         nftID: 1,
//         type: 'Helmet',
//         name: 'LIQ Helmet',
//         smallImage: 'https://liquidus.finance/LIQNFT/LIQNFT1_1200.png',
//         fullImage: 'https://liquidus.finance/LIQNFT/LIQNFT1_full.png',
//         token2nd: 'BUSD',
//         liqContract: '0x481E0c66d2cC0bC41AA75D135cC6C7137a5A21EC',
//         token2ndContract: '0x515b8eb01af8cf70ef7daa06f52abba5aaa8ca93',
//         nftContract: '0x086c0d3B3A078F8877f47AB9413912E7f8843D1d',
//         nftStaking: '0x6753a23402e44EF7518812e731B9B2Ac36DAEbd2',
//         nftAbi: NFT_HelmetContract,
//         nftStakingAbi: NFT_HelmetStakingContract,
//         liqTokenAbi: LIQSingleToken,
//         token2ndAbi: LIQSingleToken,
//         endDate: '2022-12-04',
//         netUrl: 'https://testnet.bscscan.com',
//     },
//     {
//         nftID: 2,
//         type: 'Gate',
//         name: 'Gate To Liquidus',
//         smallImage: 'https://liquidus.finance/LIQNFT/LIQNFT2_1200.png',
//         fullImage: 'https://liquidus.finance/LIQNFT/LIQNFT2_full.png',
//         token2nd: 'BUSD',
//         liqContract: '0x481E0c66d2cC0bC41AA75D135cC6C7137a5A21EC',
//         token2ndContract: '0x515b8eb01af8cf70ef7daa06f52abba5aaa8ca93',
//         nftContract: '0x086c0d3B3A078F8877f47AB9413912E7f8843D1d',
//         nftStaking: '0xcDeEeA9d3120eCBb9aFbD0Faff4E9D6bc3313a70',
//         nftAbi: NFT_HelmetContract,
//         nftStakingAbi: NFT_HelmetStakingContract,
//         liqTokenAbi: LIQSingleToken,
//         token2ndAbi: LIQSingleToken,
//         endDate: '2022-12-04',
//         netUrl: 'https://testnet.bscscan.com',
//     },
//     {
//         nftID: 3,
//         type: 'Temple',
//         name: 'Liquidus Temple',
//         smallImage: 'https://liquidus.finance/LIQNFT/LIQNFT3_1200.png',
//         fullImage: 'https://liquidus.finance/LIQNFT/LIQNFT3_full.png',
//         token2nd: 'BUSD',
//         liqContract: '0x481E0c66d2cC0bC41AA75D135cC6C7137a5A21EC',
//         token2ndContract: '0x515b8eb01af8cf70ef7daa06f52abba5aaa8ca93',
//         nftContract: '0x086c0d3B3A078F8877f47AB9413912E7f8843D1d',
//         nftStaking: '0xE81e8bab5E4362d7597A6780B0AA7B6f10ed41Cb',
//         nftAbi: NFT_HelmetContract,
//         nftStakingAbi: NFT_HelmetStakingContract,
//         liqTokenAbi: LIQSingleToken,
//         token2ndAbi: LIQSingleToken,
//         endDate: '2022-12-04',
//         netUrl: 'https://testnet.bscscan.com',
//     },
// ]


export const NFT_LIST = [
    {
        nftID: 1,
        netId: 56,
        type: 'Helmet',
        name: 'LIQ Helmet',
        smallImage: 'https://liquidus.finance/LIQNFT/LIQNFT1_1200.png',
        fullImage: 'https://liquidus.finance/LIQNFT/LIQNFT1_full.png',
        token2nd: 'BUSD',
        liqContract: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
        token2ndContract: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        nftContract: '0x81290EB62302620Ea8633967d08F25d46BCFa2F1',
        nftStaking: '0xd6499f34b7657f88B95c992D1C2Afc41fc0aAca6',
        nftAbi: NFT_HelmetContract,
        nftStakingAbi: NFT_HelmetStakingContract,
        liqTokenAbi: LIQSingleToken,
        token2ndAbi: LIQSingleToken,
        endDate: '2022-12-04',
        netUrl: 'https://bscscan.com',
    },
    {
        nftID: 2,
        netId: 56,
        type: 'Gate',
        name: 'Gate To Liquidus',
        smallImage: 'https://liquidus.finance/LIQNFT/LIQNFT2_1200.png',
        fullImage: 'https://liquidus.finance/LIQNFT/LIQNFT2_full.png',
        token2nd: 'BUSD',
        liqContract: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
        token2ndContract: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        nftContract: '0x81290EB62302620Ea8633967d08F25d46BCFa2F1',
        nftStaking: '0x2a09470aF47becCfD97885835C8dC421550ea8A4',
        nftAbi: NFT_HelmetContract,
        nftStakingAbi: NFT_HelmetStakingContract,
        liqTokenAbi: LIQSingleToken,
        token2ndAbi: LIQSingleToken,
        endDate: '2022-12-04',
        netUrl: 'https://bscscan.com',
    },
    {
        nftID: 3,
        netId: 56,
        type: 'Temple',
        name: 'Liquidus Temple',
        smallImage: 'https://liquidus.finance/LIQNFT/LIQNFT3_1200.png',
        fullImage: 'https://liquidus.finance/LIQNFT/LIQNFT3_full.png',
        token2nd: 'BUSD',
        liqContract: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
        token2ndContract: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        nftContract: '0x81290EB62302620Ea8633967d08F25d46BCFa2F1',
        nftStaking: '0x31771217B3183BE23d4ec89A930A8F844614E054',
        nftAbi: NFT_HelmetContract,
        nftStakingAbi: NFT_HelmetStakingContract,
        liqTokenAbi: LIQSingleToken,
        token2ndAbi: LIQSingleToken,
        endDate: '2022-12-04',
        netUrl: 'https://bscscan.com',
    },
]

export const CRONOS_NFT_LIST = [
    {
        nftID: 8,
        netId: 25,
        type: 'absolute',
        name: 'LIQ “Meta” Helmet (absolute)',
        smallImage: 'https://liquidus.finance/LIQNFT/LIQNFT8_1200.png',
        fullImage: 'https://liquidus.finance/LIQNFT/LIQNFT8_full.png',
        token2nd: 'USDC',
        liqContract: '0xABd380327Fe66724FFDa91A87c772FB8D00bE488',
        token2ndContract: '0xc21223249ca28397b4b6541dffaecc539bff0c59',
        nftContract: '0xB91c9bf872D3193ebc7ecD7DE6c1aD579f16013d',
        nftStaking: '0xAc66d660294A3a15336B5C4Dde77523dDF66c545',
        nftAbi: NFT_HelmetContract,
        nftStakingAbi: NFT_HelmetStakingContract,
        liqTokenAbi: LIQSingleToken,
        token2ndAbi: LIQSingleToken,
        endDate: '2023-02-07',
        netUrl: 'https://cronoscan.com',
        liqRewards: 1000,
    },
    {
        nftID: 9,
        netId: 25,
        type: 'extension',
        name: 'LIQ “Meta” Helmet (extension)',
        smallImage: 'https://liquidus.finance/LIQNFT/LIQNFT9_1200.png',
        fullImage: 'https://liquidus.finance/LIQNFT/LIQNFT9_full.png',
        token2nd: 'USDC',
        liqContract: '0xABd380327Fe66724FFDa91A87c772FB8D00bE488',
        token2ndContract: '0xc21223249ca28397b4b6541dffaecc539bff0c59',
        nftContract: '0xB91c9bf872D3193ebc7ecD7DE6c1aD579f16013d',
        nftStaking: '0x36D208a540E32FA77BF2592c3d06Ae30e6A63295',
        nftAbi: NFT_HelmetContract,
        nftStakingAbi: NFT_HelmetStakingContract,
        liqTokenAbi: LIQSingleToken,
        token2ndAbi: LIQSingleToken,
        endDate: '2023-02-07',
        netUrl: 'https://cronoscan.com',
        liqRewards: 500,
    },
    {
        nftID: 10,
        netId: 25,
        type: 'origins',
        name: 'LIQ “Meta” Helmet (origins)',
        smallImage: 'https://liquidus.finance/LIQNFT/LIQNFT10_1200.png',
        fullImage: 'https://liquidus.finance/LIQNFT/LIQNFT10_full.png',
        token2nd: 'USDC',
        liqContract: '0xABd380327Fe66724FFDa91A87c772FB8D00bE488',
        token2ndContract: '0xc21223249ca28397b4b6541dffaecc539bff0c59',
        nftContract: '0xB91c9bf872D3193ebc7ecD7DE6c1aD579f16013d',
        nftStaking: '0xFB3de800424e365F45666d30823A945BE2a6c4eD',
        nftAbi: NFT_HelmetContract,
        nftStakingAbi: NFT_HelmetStakingContract,
        liqTokenAbi: LIQSingleToken,
        token2ndAbi: LIQSingleToken,
        endDate: '2023-02-07',
        netUrl: 'https://cronoscan.com',
        liqRewards: 200,
    },
]

export const POSEIDON_NFT_LIST = [
    {
        nftID: 4,
        netId: 56,
        type: 'poseidon-1',
        name: 'Poseidon I',
        smallImage: 'https://liquidus.finance/LIQNFT/LIQNFT4_1200.png',
        fullImage: 'https://liquidus.finance/LIQNFT/LIQNFT4_full.png',
        token2nd: 'BUSD',
        liqContract: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
        token2ndContract: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        nftContract: '0x81290EB62302620Ea8633967d08F25d46BCFa2F1',
        nftStaking: '0xB5c2058FD2075F4Ce8406B2AF51d2a08c4A02f2D',
        nftAbi: NFT_HelmetContract,
        nftStakingAbi: NFT_HelmetStakingContract,
        liqTokenAbi: LIQSingleToken,
        token2ndAbi: LIQSingleToken,
        endDate: '2022-12-15',
        netUrl: 'https://bscscan.com',
    },
    {
        nftID: 5,
        netId: 56,
        type: 'poseidon-2',
        name: 'Poseidon II',
        smallImage: 'https://liquidus.finance/LIQNFT/LIQNFT5_1200.png',
        fullImage: 'https://liquidus.finance/LIQNFT/LIQNFT5_full.png',
        token2nd: 'BUSD',
        liqContract: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
        token2ndContract: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        nftContract: '0x81290EB62302620Ea8633967d08F25d46BCFa2F1',
        nftStaking: '0x9992D7d7523214bcFCCe0e15E9365Db5De567D6B',
        nftAbi: NFT_HelmetContract,
        nftStakingAbi: NFT_HelmetStakingContract,
        liqTokenAbi: LIQSingleToken,
        token2ndAbi: LIQSingleToken,
        endDate: '2022-12-15',
        netUrl: 'https://bscscan.com',
    },
    {
        nftID: 6,
        netId: 56,
        type: 'poseidon-3',
        name: 'Poseidon III',
        smallImage: 'https://liquidus.finance/LIQNFT/LIQNFT6_1200.png',
        fullImage: 'https://liquidus.finance/LIQNFT/LIQNFT6_full.png',
        token2nd: 'BUSD',
        liqContract: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
        token2ndContract: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        nftContract: '0x81290EB62302620Ea8633967d08F25d46BCFa2F1',
        nftStaking: '0x1512A82d2ADDceff313C2BF326c84D63caa752E2',
        nftAbi: NFT_HelmetContract,
        nftStakingAbi: NFT_HelmetStakingContract,
        liqTokenAbi: LIQSingleToken,
        token2ndAbi: LIQSingleToken,
        endDate: '2022-12-15',
        netUrl: 'https://bscscan.com',
    },
   
]

export const EARLY_ADOPTER_NFT_LIST = [
    {
        nftID: 7,
        netId: 56,
        type: 'early-adopter',
        name: 'LIQ Early Adopter',
        smallImage: 'https://liquidus.finance/LIQNFT/LIQNFT7_1200.png',
        fullImage: 'https://liquidus.finance/LIQNFT/LIQNFT7_full.png',
        token2nd: 'BUSD',
        liqContract: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
        token2ndContract: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        nftContract: '0x81290EB62302620Ea8633967d08F25d46BCFa2F1',
        nftStaking: '0x22015f8F09aa3c04681c748ab69effE72E3f0013',
        nftAbi: NFT_HelmetContract,
        nftStakingAbi: NFT_HelmetStakingContract,
        liqTokenAbi: LIQSingleToken,
        token2ndAbi: LIQSingleToken,
        endDate: '2022-12-24',
        netUrl: 'https://bscscan.com',
    },
]

export const SWAP_CONTRACTS = {
    56: {
        name: 'BNB',
        abi: SwapBscABI,
        address: '0xf9736ec3926703e85C843FC972BD89A7f8E827C0',
        tokenAddress: '0x4b106b91c5fe4ae933cda197b9caf91a07bdae15',
        singleTokenAddress: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
        tokenAbi: LIQSingleToken
    },
    25: {
        name: 'CRONOS',
        abi: SwapCronosABI,
        address: '0x639A647fbe20b6c8ac19E48E2de44ea792c62c5C',
        tokenAddress: '0xABd380327Fe66724FFDa91A87c772FB8D00bE488',
        singleTokenAddress: '0xABd380327Fe66724FFDa91A87c772FB8D00bE488',
        tokenAbi: Crona_LP_Token
    },
    1: {
        name: 'ETH',
        abi: SwapEthABI,
        address: '0xe95fd76cf16008c12ff3b3a937cb16cd9cc20284',
        tokenAddress: '0x5F69b7Ab8F7cAb199a310Fd5A27B43Fef44ddcC0',
        singleTokenAddress: '0x5F69b7Ab8F7cAb199a310Fd5A27B43Fef44ddcC0',
        tokenAbi: LIQSingleToken
    },
    137: {
        name: 'MATIC',
        abi: SwapMaticABI,
        address: '0xafaace7138ab3c2bcb2db4264f8312e1bbb80653',
        tokenAddress: '0x4036f3d9c45a20f44f0b8b85dd6ca33005ff9654',
        singleTokenAddress: '0x4036f3d9c45a20f44f0b8b85dd6ca33005ff9654',
        tokenAbi: LIQSingleToken
    }
}

export const BRIDGE_API = 'https://bridgeapi.anyswap.exchange/v3/serverinfoV3';
export const HISTORY_API = 'https://bridgeapi.anyswap.exchange/v2/history/details';

export const BUY_LIQ_CONTRACTS = {
    1: {
        address1: '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D',
        address2: '',
        abi: BuyLiqABI,
        tokenAddress: '0x5F69b7Ab8F7cAb199a310Fd5A27B43Fef44ddcC0',
        tokenAbi: LIQSingleToken,
        pathArray: [
            '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
            '0x5F69b7Ab8F7cAb199a310Fd5A27B43Fef44ddcC0'
        ]
    },
    56: {
        address1: '0x3a6d8cA21D1CF76F653A67577FA0D27453350dD8',
        address2: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
        abi: BuyLiqABI,
        tokenAddress: '0xc7981767f644c7f8e483dabdc413e8a371b83079',
        tokenAbi: LIQSingleToken,
        pathArray: [
            '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
            '0xc7981767f644c7f8e483dabdc413e8a371b83079'
        ]
    },
    25: {
        address1: '0xcd7d16fb918511bf7269ec4f48d61d79fb26f918',
        address2: '0x145677FC4d9b8F19B5D56d1820c48e0443049a30',
        abi: BuyLiqCronosABI,
        tokenAddress: '0xabd380327fe66724ffda91a87c772fb8d00be488',
        tokenAbi: Crona_LP_Token,
        pathArray: [
            '0x5c7f8a570d578ed84e63fdfa7b1ee72deae1ae23',
            '0xabd380327fe66724ffda91a87c772fb8d00be488'
        ]
    },
    137: {
        address1: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
        address2: '',
        abi: BuyLiqABI,
        tokenAddress: '0x4036f3d9c45a20f44f0b8B85dD6CA33005fF9654',
        tokenAbi: LIQSingleToken,
        pathArray: [
            '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
            '0x4036f3d9c45a20f44f0b8B85dD6CA33005fF9654'
        ]
    }
    
}
