import React, { useEffect, useState, useCallback } from 'react';
import { Grid } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Logo from '../assets/images/logo.svg';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CircleArrowDown from '../assets/images/circle-arrow-down.svg';
import CircleArrowRight from '../assets/images/circle-arrow-right.svg';
import InvertedLogo from '../assets/images/logo_inverted.png';
import DetailIcon from '../assets/images/copy_icon.png';
import AuditedLogo from '../assets/images/logo_audited.png';
import MintNFTPopup from '../components/MintNFTPopup';
import FarmNFTPopup from '../components/FarmNFTPopup';
import Circle from '../assets/images/circle.svg';
import WalletsPopup from '../components/WalletsPopop';
import { readableNumber, parseBNumber, formatDate, calRemainDate, makeBNumber } from '../services/utils';
import {
    NFT_LIST,
    CRONOS_NFT_LIST,
    POSEIDON_NFT_LIST,
    EARLY_ADOPTER_NFT_LIST
} from '../abis/constants';
import Spinner from '../components/Spinner';
import { getBNBPrice, getLIQPrice } from '../services/api';
import { COMPOUNDING_PERIOD } from '../services/config';
import WithdrawNFTPopup from '../components/WithdrawNFTPopup';
import { actions } from '../redux/common.redux';
import BuyLiqPopup from '../components/BuyLiqPopup';
import { getWallet } from '../helper/connect-wallet';

const NFTDetail = (props) => {
    const { id: nft_id } = useParams();
    const nftID = parseInt(nft_id);
    const { goBack } = useHistory();
    const refreshCount = useSelector(state => state.common.refreshCount);
    const dispatch = useDispatch();
    
    const [web3Client, setWeb3Client] = useState(null);
    const [userAccount, setUserAccount] = useState('');
    const [nft, setNft] = useState(null);
    const [dropId, setDropId] = useState(1);
    const [mintPopup, setMintPopup] = useState(false);
    const [farmPopup, setFarmPopup] = useState(false);
    const [openWithdrawPopup, setOpenWithdrawPopup] = useState(false);
    const [liqAmount, setLiqAmount] = useState(0);
    const [token2ndAmount, setToken2ndAmount] = useState(0);
    const [editon, setEditon] = useState(0);
    const [mintedAmount, setMintedAmount] = useState(0);
    const [apr, setApr] = useState(0);
    const [apy, setApy] = useState(0);
    const [mintPrice, setMintPrice] = useState(0);
    const [totalRewards, setTotalRewards] = useState(0);
    const [totalPrice, setTotalPrice] = useState(0);
    const [startID, setStartID] = useState(0);
    const [endID, setEndID] = useState(0);
    const [stakedNFTIds, setStakedNFTIds] = useState([]);
    const [depositedNFTNumber, setDepositedNFTNumber] = useState(0);
    const [liqEarned, setLiqEarned] = useState(0);
    const [liqEarnedDol, setLiqEarnedDol] = useState(0);
    const [stakeContract, setStakeContract] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingHarvest, setLoadingHarvest] = useState(false);
    const [openWalletPopup, setOpenWalletPopup] = useState(false);
    const [openBuyPopup, setOpenBuyPopup] = useState(false);
    const decimals = 18;

    useEffect(() => {
        if (nftID < 4) {
            const temp = NFT_LIST.find(x => x.nftID === nftID);
            setNft(temp);
        } else if (nftID < 7) {
            const temp = POSEIDON_NFT_LIST.find(x => x.nftID === nftID);
            setNft(temp);
        } else if (nftID === 7) {
            const temp = EARLY_ADOPTER_NFT_LIST.find(x => x.nftID === nftID);
            setNft(temp);
        } else if (nftID < 11) {
            const temp = CRONOS_NFT_LIST.find(x => x.nftID === nftID);
            setNft(temp);
        }
    }, [nftID])

    const getNFTInfo = useCallback(async () => {
        const { web3, address } = await getWallet();
        setWeb3Client(web3);
        setUserAccount(address);

        try {
            if (!web3?.eth) return;
            if (!nft) return;

            const bnbPrice = await getBNBPrice();
            const liqPrice = await getLIQPrice();

            const nftContract = new web3.eth.Contract(nft.nftAbi, nft.nftContract);
            const token2nd_contract = new web3.eth.Contract(nft.token2ndAbi, nft.token2ndContract);
            const decimal_token2nd = await token2nd_contract.methods.decimals().call();

            const nftInfo = await nftContract.methods.NftInfo(nft.nftID).call();
            const liq = parseBNumber(nftInfo.priceInLIQ, decimals)
            setLiqAmount(liq);
            const token2nd = nft.token2nd === 'BNB' ?
                parseBNumber(nftInfo.priceInBNB, decimal_token2nd) :
                parseBNumber(nftInfo.priceIn2ndToken, decimal_token2nd);
            setToken2ndAmount(token2nd);
            setEditon(nftInfo.maximumAmount);
            setMintedAmount(nftInfo.mintedAmount);

            /* APR calculation */
            const sumPrice = liq * liqPrice + token2nd * (nft.token2nd === 'BNB' ? bnbPrice : 1);
            setMintPrice(sumPrice);
            const nftStakingContract = new web3.eth.Contract(nft.nftStakingAbi, nft.nftStaking);
            setStakeContract(nftStakingContract);

            //todo
            const annualReward = nft.liqRewards
                ? makeBNumber(nft.liqRewards, decimals)
                : await nftStakingContract.methods.annualReward().call();
            const totalReward = parseBNumber(annualReward, decimals);
            setTotalRewards(totalReward);
            const totalRewardPrice = totalReward * liqPrice;
            setTotalPrice(totalRewardPrice);
            const anualPercentReward = (totalRewardPrice) * 100 / sumPrice;
            setApr(readableNumber(anualPercentReward));
            const anualPercentYield = (1 + (anualPercentReward / 100) / COMPOUNDING_PERIOD) ** COMPOUNDING_PERIOD - 1;
            setApy(readableNumber(anualPercentYield * 100));

            /* TokenID range calculation */
            setStartID(parseInt(nft.nftID) * 1000000 + 1);
            setEndID(parseInt(nft.nftID) * 1000000 + parseInt(nftInfo.maximumAmount));

            /* Tokens of owner */
            // const tokens = await nftContract.methods.tokensOfHolder(account).call();

            const depositedNFTs = await nftContract.methods.balanceOf(nft.nftStaking).call();
            setDepositedNFTNumber(depositedNFTs);

            /* NFT deposited */
            const stakedTokens = address ? await nftStakingContract.methods.getStakedNftIDs(address).call() : null;
            setStakedNFTIds(stakedTokens);
            const pendingReward = address ? await nftStakingContract.methods.pendingReward(address).call() : null;
            const liq_earned = parseBNumber(pendingReward, decimals);
            setLiqEarned(readableNumber(liq_earned));
            setLiqEarnedDol(readableNumber(liq_earned * liqPrice));

        } catch (err) {
            console.log(err);
        }
    }, [nft])

    useEffect(() => {
        getNFTInfo()
    }, [getNFTInfo, refreshCount])


    useEffect(() => {
        const interval = setInterval(() => {
            getNFTInfo();
        }, 10000);

        return () => clearInterval(interval);
    }, [getNFTInfo])


    const handleHarvest = async () => {
        setLoadingHarvest(true);
        try {
            await stakeContract.methods.harvest(userAccount).send({ 
                from: userAccount,
                maxPriorityFeePerGas: null,
                maxFeePerGas: null, 
              });
            dispatch(actions.changeRefresh());
            setLoadingHarvest(false);
        } catch (err) {
            console.log(err);
            setLoadingHarvest(false);
        }
    }

    if (!nft) return null;
    return (
        <div>
            <Grid
                container
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                className='detail-header'
            >
                <Grid item>
                    <a href="http://liquidus.finance">
                        <img src={Logo} alt="logo" className='logo' />
                    </a>
                </Grid>
                <Grid item onClick={() => {goBack()}}>
                    <HighlightOffIcon className='close-icon'/>
                </Grid>
            </Grid>
            <Grid className='detail-container'>
                <Grid container>
                    <Grid item xs={12} md={6} className='box-padding detail-image-box'>
                        <a href={nft?.fullImage} target='_blank' rel="noopener noreferrer">
                            <div style={{backgroundImage: `url(${nft?.smallImage})`}} className='nft-detail-img' />
                        </a>
                        <div className='circle pos-1'>
                            <img src={Circle} alt="" />
                        </div>
                        <div className='circle pos-2'>
                            <img src={Circle} alt="" />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} className='box-padding pt-6 nft-detail'>
                        <h1 className='nft-heading'>{nft?.name}</h1>
                        <h3>{`${mintedAmount}/${editon} limited edition NFT`}</h3>
                        <Grid container className='mt-11 mb-16 mint-info'>
                            <Grid item xs>
                                <h3>Price to mint</h3>
                                {liqAmount > 0 && (
                                    <h2>{`${liqAmount} LIQ`}</h2>
                                )}
                                {token2ndAmount > 0 && (
                                    <>
                                    <h2 className='mb-3'>{`${token2ndAmount} ${nft?.token2nd}`}</h2>
                                    <h5 style={{fontStyle: 'italic'}}>{`$${readableNumber(mintPrice)}`}</h5>
                                    </>
                                )}
                            </Grid>
                            <Grid item xs className='apy-info'>
                                <h3>APY</h3>
                                <h2 style={{color: '#17E7D6'}}>{apy}%</h2>
                                <h3>Total Rewards</h3>
                                <h4>{totalRewards} LIQ</h4>
                                <h5 className='mb-4' style={{fontStyle: 'italic'}}>${readableNumber(totalPrice)}</h5>
                                <h6 style={{fontStyle: 'italic'}}>{`Ends ${formatDate(nft.endDate)} (${calRemainDate(nft.endDate)})`}</h6>
                            </Grid>
                        </Grid>
                        <Grid container justifyContent='center' spacing={3}>
                            <Grid item>
                                <button className='btn-round-lg' onClick={() => setMintPopup(true)}>
                                    Mint artwork
                                </button>
                            </Grid>
                            <Grid item>
                                <button className='btn-border-lg' onClick={() => setFarmPopup(true)}>
                                    Farm NFT
                                </button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <div className='pb-15 grid-box'>
                    <div className='box-padding nft-liq'>
                        <div className='border-box'>
                            <div>
                                <h5 className='mb-2'>NFT DEPOSITED</h5>
                                {stakedNFTIds && stakedNFTIds.length > 0 && stakedNFTIds.map((item, idx) => {
                                    return (
                                        <div className='id-box' key={idx}>
                                            <h4>ID {item}</h4>
                                            <span onClick={() => setOpenWithdrawPopup(true)} style={{cursor: 'pointer'}}>-</span>
                                        </div>
                                    )
                                })}
                                {(!stakedNFTIds || stakedNFTIds.length === 0) && (
                                    <div style={{fontSize: 12, color: '#696969', marginBottom: '8px'}}>
                                        There is no deposited NFT
                                    </div>
                                )}
                                <p className='mb-2' style={{fontSize: 11, color: '#a0a8bd'}}>
                                    Token ID range of this farm: {`${startID} - ${endID}`}
                                </p>
                                <a href={`${nft.netUrl}/address/${nft.nftContract}`} target='_blank' rel="noopener noreferrer" style={{color: '#cacfdb', fontWeight: 500}}>
                                    View NFT Contract
                                    <span className='ml-2'><img src={DetailIcon} alt="" className='detail-icon'/></span>
                                </a>
                            </div>
                            <div className='btn-count flex-center m-2' onClick={() => setFarmPopup(true)}>+</div>
                        </div>
                        <div className='border-box'>
                            <div>
                                <h5 style={{paddingBottom: 10}}>LIQ EARNED</h5>
                                <h4>{liqEarned}</h4>
                                <h6>${liqEarnedDol}</h6>
                            </div>
                            <button className='btn-normal m-2' onClick={() => handleHarvest()}>
                                {loadingHarvest ? (
                                    <Spinner color='primary'/>
                                ) : (
                                    <span>Harvest</span>
                                )}
                            </button>
                        </div>
                        <div className='general-info pb-3'>
                            <h5>Number of NFTs Deposited</h5>
                            <h5>{depositedNFTNumber}</h5>
                        </div>
                        <div className='general-info pb-3'>
                            <h5>Yearly Rewards per NFT</h5>
                            <h5>{totalRewards} LIQ</h5>
                        </div>
                        <div className='general-info pb-3'>
                            <h5>APR</h5>
                            <h5>{apr}%</h5>
                        </div>
                        <div className='general-info pb-3'>
                            <h5>End Date</h5>
                            <h5>{`${formatDate(nft.endDate)}`}</h5>
                        </div>
                    </div>
                    <div className='box-padding nft-drop'>
                        <div className='mb-7-5'>
                            <div className='mb-5 flex-between' style={{cursor: 'pointer'}} onClick={() => setDropId(1)}>
                                <h3>Details</h3>
                                {dropId === 1 ? (
                                    <img src={CircleArrowDown} alt="" className='close-icon'/>
                                ) : (
                                    <img src={CircleArrowRight} alt="" className='close-icon'/>
                                )}
                            </div>
                            {dropId === 1 && (
                                <div className='detail-text'>
                                    <p>Liquidus NFTs are limited editions.</p>
                                    <p>They can be purchased (minted) by paying one or more tokens. This will happen automatically when you confirm the Mint transaction.</p>
                                    <p>Each Liquidus NFT can be used to farm LIQ rewards over a set period of time. After the end of the farm, rewards stop.</p>
                                    <p>You can withdraw your NFT anytime and send it to other addresses or sell it. An official Liquidus marketplace is under development.</p>
                                </div>
                            )}
                        </div>
                        <div className='mb-7-5'>
                            <div className='mb-5 flex-between' style={{cursor: 'pointer'}} onClick={() => setDropId(2)}>
                                <h3>Creator</h3>
                                {dropId === 2 ? (
                                    <img src={CircleArrowDown} alt="" className='close-icon'/>
                                ) : (
                                    <img src={CircleArrowRight} alt="" className='close-icon'/>
                                )}
                            </div>
                            {dropId === 2 && (
                                <div className='flex-start'>
                                    <img src={InvertedLogo} alt="" className='liqlogo-box' />
                                    <h3>Liquidus Original</h3>
                                </div>
                            )}
                        </div>
                        <div>
                            <div className='mb-5 flex-between' style={{cursor: 'pointer'}} onClick={() => setDropId(3)}>
                                <h3>Additional information</h3>
                                {dropId === 3 ? (
                                    <img src={CircleArrowDown} alt="" className='close-icon'/>
                                ) : (
                                    <img src={CircleArrowRight} alt="" className='close-icon'/>
                                )}
                            </div>
                            {dropId === 3 && (
                                <div>
                                    <p className='mb-2'>Read more here:</p>
                                    <a href="https://docs.liquidus.finance/" target='_blank' rel="noopener noreferrer">docs.liquidus.finance</a>
                                    <p className='pt-6 mb-2'>or see our:</p>
                                    <a href="https://docs.liquidus.finance/faq/liquidus-nfts-and-farm" target='_blank' rel="noopener noreferrer">FAQs</a>
                                </div>
                            )}
                        </div>
                    </div>

                </div>
                <div className='footer-logo'>
                    <h3>Audited by</h3>
                    <a href="https://www.liquidus.finance/LIQNFT_Audit.pdf" target='_blank' rel="noopener noreferrer">
                        <img src={AuditedLogo} alt="" className=''/>
                    </a>
                </div>
            </Grid>
            {mintPopup && (
                <MintNFTPopup
                    setOpenPopup={setMintPopup}
                    setFarmPopup={setFarmPopup}
                    setOpenWalletPopup={setOpenWalletPopup}
                    setOpenBuyPopup={setOpenBuyPopup}
                    web3={web3Client}
                    userAccount={userAccount}
                    nft={nft}
                    setLoading={setLoading}
                />
            )}
            {farmPopup && (
                <FarmNFTPopup
                    setOpenPopup={setFarmPopup}
                    setOpenWalletPopup={setOpenWalletPopup}
                    web3={web3Client}
                    userAccount={userAccount}
                    nft={nft}
                    setLoading={setLoading}
                />
            )}
            {openWithdrawPopup && (
                <WithdrawNFTPopup
                    setOpenPopup={setOpenWithdrawPopup}
                    web3={web3Client}
                    userAccount={userAccount}
                    nft={nft}
                    setLoading={setLoading}
                />
            )}
            {openWalletPopup && (
                <WalletsPopup
                    setOpenPopup={setOpenWalletPopup}
                />
            )}
            {openBuyPopup && (
                <BuyLiqPopup
                    setOpenPopup={setOpenBuyPopup}
                    setOpenWalletPopup={setOpenWalletPopup}
                    web3={web3Client}
                    userAccount={userAccount}
                />
            )}
            {loading && (
                <Spinner shape='ellipsis' setLoading={setLoading} />
            )}
        </div>
    )
}

export default NFTDetail;
