import { API_KEY, BACKEND_URL, BSCSCAN_API_URL } from "./config";
import { parseBNumber, queryUrl } from "./utils";
import { BRIDGE_API, CONTRACT_FOR_BNB, LIQ_BNB_CONTRACT, HISTORY_API } from "../abis/constants";
import Web3 from "web3";
import axios from 'axios';

export const getBEP20TokenAccountBalanceByContractAddress = async (data) => {
    const params = {
        ...data,
        module: 'account',
        action: 'tokenbalance',
        tag: 'latest',
        apikey: API_KEY
    }
    return await (await fetch(queryUrl(BSCSCAN_API_URL, params))).json();
}

// export const getBNBPrice = async () => {
//     return await (await fetch('https://api.pancakeswap.info/api/v2/tokens/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c')).json();
// }

export const getBNBPrice = async () => {
    const web3 = new Web3('https://bsc-dataseed1.binance.org');
    // const web3 = new Web3('https://evm-cronos.crypto.org:443');
    // return await (await fetch('https://api.pancakeswap.info/api/v2/tokens/0xc7981767f644c7f8e483dabdc413e8a371b83079')).json();
    const bnbContract = new web3.eth.Contract(CONTRACT_FOR_BNB.abi, CONTRACT_FOR_BNB.address);
    const balanceInfo = await bnbContract.methods.getReserves().call();
    const busdAmount = parseBNumber(balanceInfo._reserve1, 18);
    const bnbAmount = parseBNumber(balanceInfo._reserve0, 18);
    const bnbPrice = busdAmount / bnbAmount;

    return bnbPrice;
}

export const getLIQPrice = async () => {
    const web3 = new Web3('https://bsc-dataseed1.binance.org');
    // return await (await fetch('https://api.pancakeswap.info/api/v2/tokens/0xc7981767f644c7f8e483dabdc413e8a371b83079')).json();
    const bnbContract = new web3.eth.Contract(CONTRACT_FOR_BNB.abi, CONTRACT_FOR_BNB.address);
    const balanceInfo = await bnbContract.methods.getReserves().call();
    const busdAmount = parseBNumber(balanceInfo._reserve1, 18);
    const bnbAmount = parseBNumber(balanceInfo._reserve0, 18);
    const bnbPrice = busdAmount / bnbAmount;

    const liqContract = new web3.eth.Contract(LIQ_BNB_CONTRACT.abi, LIQ_BNB_CONTRACT.address);
    const liqInfo = await liqContract.methods.getReserves().call();
    const bnb = parseBNumber(liqInfo._reserve0, 18);
    const liqAmount = parseBNumber(liqInfo._reserve1, 18);
    const liqPrice = bnb * bnbPrice / liqAmount;

    return liqPrice;
}

export const getBridgeSwapData = async () => {
    return await (await fetch(`${BRIDGE_API}?chainId=all&version=NATIVE`)).json();
}

export const getTransferStatus = async (txhash) => {
    return await (await fetch(`${HISTORY_API}?params=${txhash}`)).json();
}

export const registerUser = async (address, location) => {
    const response = await fetch(`${BACKEND_URL}/liq-holdings/user/register`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({address, location}),
    });
    return await response.json();
}

export const getIPAddress = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    console.log('location: ', res?.data);
    return res?.data;
}