import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../redux/wallet.redux';
import { getDefaultWallet } from "../helper/connect-wallet";

const useStyles = makeStyles(theme => ({
    overlay: {
        position: 'fixed',
        width: '100%',
        height: '100vh',
        top: 0,
        left: 0,
        zIndex: 1,
        backgroundColor: 'rgba(17, 36, 85, 0)',
    },
    container: {
        position: 'absolute',
        right: 0,
        top: 54,
        backgroundColor: '#131517',
        width: 160,
        padding: '10px 16px',
        zIndex: 300,
        borderRadius: 12,
        color: '#ffffff',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
    }
}))

export default function SettingPopup(props) {
    const { setOpenPopup } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const wallet = useSelector(state => state.wallet.wallet);

    const disconnect = async () => {
        const walletType = localStorage.getItem('walletType');
        const netId = localStorage.getItem('netId');

        if (walletType === 'wallet-connect') {
            await wallet.wcProvider.disconnect();
            localStorage.clear();
            localStorage.setItem('netId', netId);
            window.location.reload();
            setOpenPopup(false);
        } else if (walletType === 'defi-wallet') {
            await wallet.wcConnector.deactivate();
            localStorage.clear();
            localStorage.setItem('netId', netId);
            window.location.reload();
            setOpenPopup(false);
        } else if (walletType === 'metamask') {
            localStorage.clear();
            localStorage.setItem('netId', netId);
            const defaultWallet = getDefaultWallet();
            dispatch(actions.updateWalletAction(defaultWallet));
            setOpenPopup(false);
        }
    }

    return (
        <>
            <Box className= {classes.overlay} onClick={() => setOpenPopup(false)} />
            <Box className={classes.container} onClick={() => disconnect()}>
                <div>Disconnect</div>
                <div style={{paddingTop: 6}}><LogoutIcon style={{fontSize: 20}}/></div>
            </Box>
        </>
    )
}