import React from 'react';
import {
    CircularProgressbarWithChildren,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const Chart = (props) => {
    const { percent } = props;
    return (
        <div className='chart'>
            <CircularProgressbarWithChildren
                value={percent}
                strokeWidth={4}
                styles={buildStyles({
                    pathColor: "#17E7D6",
                    trailColor: "#E0E4F0",
                    strokeLinecap: "butt"
                })}
            >
                <h1>{percent} %</h1>
                <span className='text-16 text-bold'>Completed</span>
            </CircularProgressbarWithChildren>
        </div>
    )
}

export default Chart;