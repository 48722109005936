import { Stack, Box, Typography } from "@mui/material";
import InfoIcon from '../../../assets/images/icon-info.svg';

export const ShareItem = ({label, value}) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" alignItems="center" spacing={1}>
        <Box sx={{width: "14px", height: "14px"}}><img src={InfoIcon} alt="info-icon" /></Box>
        <div><Typography sx={{fontFamily: "Montserrat", fontSize: "18px", fontWeight: 400, lineHeight: "30px", color: 'black'}}>{label}</Typography></div>
      </Stack>
      <div><Typography sx={{fontFamily: "Montserrat", fontSize: "24px", fontWeight: 600, lineHeight: "30px", color: 'black'}}>{value}</Typography></div>
    </Stack>
  );
};
