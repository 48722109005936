import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useCallback } from 'react';
import LiqIcon from '../assets/images/liquidus_logo_round.png';
import CoinmarketIcon from '../assets/images/coinmarket-icon.png';
import { COINMARKET_CONTRACT } from '../abis/constants';
import { useSelector } from 'react-redux';
import WalletsPopup from './WalletsPopop';
import Spinner from './Spinner';
import { getWallet } from '../helper/connect-wallet';

export default function CoinmarketPopup(props) {
    const { web3Prop, setOpenPopup, accountProp } = props;
    const history = useHistory();
    const refreshCount = useSelector(state => state.common.refreshCount);
    const [withdrawLoading, setWithdrawLoading] = useState(false);
    const [openWalletPopup, setOpenWalletPopup] = useState(false);
    const [isWhitelisted, setIsWhitelisted] = useState(false);
    const [isClaimed, setIsClaimed] = useState(false);
    const [web3Client, setWeb3Client] = useState(null);
    const [userAccount, setUserAccount] = useState('');

    const initProcess = useCallback(async () => {
        setWeb3Client(web3Prop);
        setUserAccount(accountProp);
        localStorage.setItem('netId', '56');
        const {web3, address} = await getWallet();
        if (!web3 || !address) return;
        setWeb3Client(web3);
        setUserAccount(address);

        try {
            const cmContract = new web3.eth.Contract(COINMARKET_CONTRACT.abi, COINMARKET_CONTRACT.address);
            const whitelisted = await cmContract.methods.WhitelistedAddress(address).call();
            setIsWhitelisted(whitelisted);
            const claimed = await cmContract.methods.Claimed(address).call();
            setIsClaimed(claimed);
        } catch (err) {
            console.log(err);
        }
    }, [web3Prop, accountProp])

    useEffect(() => {
        initProcess();
    }, [initProcess, refreshCount])

    const claimAirdrop = async () => {
        if (!web3Client || !userAccount) return;
        if (isClaimed) return;

        try {
            setWithdrawLoading(true);
            const cmContract = new web3Client.eth.Contract(COINMARKET_CONTRACT.abi, COINMARKET_CONTRACT.address);
            await cmContract.methods.Withdraw().send({from: userAccount});
            setWithdrawLoading(false);
            setIsClaimed(true);
        } catch (err) {
            console.log(err);
            setWithdrawLoading(false);
        }
    }

    return (
        <div className='bg-overlay flex-center'>
            <div className='popup-wrapper bridge-wrapper'>
                <div className='popup-box position-relative' style={{ overflow: 'hidden' }}>
                    <div className='cm-close'>
                        <IconButton
                            aria-label="close"
                            onClick={() => setOpenPopup(false)}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                    {!userAccount ? (
                        <div className='bridge-body pb-6'>
                            <div style={{ padding: '178px 16px' }}>
                                <div className='cm-header'>
                                    <img src={CoinmarketIcon} alt="" />
                                </div>
                                <div className='text-16 text-bold text-center'>Please connect your wallet!</div>
                            </div>
                            <div className='flex-center'>
                                <div
                                    className='btn-normal mb-4 text-center'
                                    style={{ padding: '13px 62px' }}
                                    onClick={() => setOpenWalletPopup(true)}
                                >
                                    Connect wallet
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            {isWhitelisted ? (
                                <div>
                                    <div className='cm-header'>
                                        <img src={CoinmarketIcon} alt="" />
                                    </div>
                                    <div className='px-5 pb-6 bridge-body'>
                                        <div className='cm-body'>
                                            <p>Congratulations, you were a winner on the CoinMarketCap Airdrop!</p>
                                            <div className='flex-center'>
                                                <img src={LiqIcon} alt="" />
                                            </div>
                                            <h2>LIQ <span>5.00</span></h2>
                                            <h5>Received</h5>
                                        </div>
                                        <div className='flex-center'>
                                            <div
                                                className='btn-normal mb-4 text-center'
                                                style={{
                                                    padding: '13px 62px',
                                                }}
                                                onClick={() => claimAirdrop()}
                                            >
                                                {withdrawLoading ? (
                                                    <Spinner color='primary' />
                                                ) : (
                                                    <span>
                                                        {isClaimed ? 'Already claimed' : 'Claim Airdrop'}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className='bridge-body pb-6'>
                                    <div style={{ padding: '178px 16px' }}>
                                        <div className='cm-header'>
                                            <img src={CoinmarketIcon} alt="" />
                                        </div>
                                        <div className='text-16 text-bold text-center'>Sorry, you aren't eligble for the CoinMarketCap Airdrop!</div>
                                    </div>
                                    <div className='flex-center'>
                                        <div
                                            className='btn-normal mb-4 text-center'
                                            style={{
                                                padding: '13px 62px',
                                            }}
                                            onClick={() => {
                                                history.push('/');
                                                setOpenPopup(false);
                                            }}
                                        >
                                            Back
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
            {openWalletPopup && (
                <WalletsPopup
                    setOpenPopup={setOpenWalletPopup}
                />
            )}
        </div>
    )
}