import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from 'react-redux';
import MetamaskIcon from '../assets/images/metamask.png';
import TrustIcon from '../assets/images/trust.png';
import Wlconnect from '../assets/images/wlconnect.png';
import CryptoIcon from '../assets/images/crypto-com-logo.png';
import * as Metamask from '../helper/wallet-metamask';
import * as WalletConnect from '../helper/wallet-connect';
import * as Defiwallet from '../helper/defiwallet';
import { actions } from '../redux/wallet.redux';
import { getIPAddress, registerUser } from '../services/api';

toast.configure();

const useStyles = makeStyles(theme => ({
    container: {
        paddingLeft: 24,
        paddingRight: 24,
        width: '100%',
        maxWidth: 500
    },
    wrapper: {
        backgroundColor: '#131517',
        borderRadius: 16,
        zIndex: 300,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 0 12px 24px',
    },
    title: {
        color: '#ffffff',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 20,
        fontWeight: 500
    },
    body: {
        padding: '0 16px 16px'
    },
    walletBox: {
        backgroundColor: '#212329',
        border: '1px solid #31343E',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 60,
        borderRadius: 12,
        padding: '0 15px',
        marginTop: 16,
        cursor: 'pointer'
    },
    walletLabel: {
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 16,
        fontWeight: 400,
        color: '#ffffff'
    },
    iconBox: {
        width: 30,
        height: 30
    },
    walletIcon: {
        width: '100%',
    }
}))

export default function WalletsPopup(props) {
    const { setOpenPopup } = props;
    const dispatch = useDispatch();
    const classes = useStyles();

    const registerUserInfo = async (address) => {
        const location = await getIPAddress();
        registerUser(address, location)
            .then(res => {
                console.log('ok');
            })
            .catch(err => {
                console.log(err);
            });
    }

    const connectMetamask = async () => {
        if (window.ethereum) {
            if (window.ethereum.isMetaMask) {
                const newWallet = await Metamask.connect();
                dispatch(actions.updateWalletAction(newWallet));
                setOpenPopup(false);
                registerUserInfo(newWallet.address).then();
            } else {
                toast.error("Please install Metamask first", {autoClose: 5000, theme: 'colored', position: 'top-center'});  
            }
        } else {
            toast.error("Please install Metamask first", {autoClose: 5000, theme: 'colored', position: 'top-center'});
        }
    }

    const connectTrustWallet = async () => {
        if (window.ethereum) {
            if (window.ethereum.isMetaMask) {
                toast.error("Please install Trust wallet and open the website on Trust/DApps", {autoClose: 5000, theme: 'colored', position: 'top-center'});  
            } else {
                const newWallet = await Metamask.connect();
                dispatch(actions.updateWalletAction(newWallet));
                setOpenPopup(false);
                registerUserInfo(newWallet.address).then();
            }
        } else {
            toast.error("Please install Trust wallet and open the website on Trust/DApps", {autoClose: 5000, theme: 'colored', position: 'top-center'});
        }
    }

    const connectWalletConnect = async () => {
        const newWallet = await WalletConnect.connect();
        dispatch(actions.updateWalletAction(newWallet));
        setOpenPopup(false);
        registerUserInfo(newWallet.address).then();
    }

    const connectCryptoDefiWallet = async () => {
        const newWallet = await Defiwallet.connect();
        dispatch(actions.updateWalletAction(newWallet));
        setOpenPopup(false);
        registerUserInfo(newWallet.address).then();
    }

    return (
        <Box className='bg-overlay flex-center'>
            <Box className={classes.container}>
                <Box className={classes.wrapper}>
                    <Box className={classes.header}>
                        <h4 className={classes.title}>Connect to a wallet</h4>
                        <IconButton
                            aria-label="close"
                            onClick={() => setOpenPopup(false)}
                        >
                            <CloseIcon style={{color: '#ffffff'}}/>
                        </IconButton>
                    </Box>
                    <Box className={classes.body}>
                        <Box className={'wallet-box'} onClick={() => connectMetamask()}>
                            <h5 className={classes.walletLabel}>Metamask</h5>
                            <div className={classes.iconBox}>
                                <img src={MetamaskIcon} alt="" className={classes.walletIcon}/>
                            </div>
                        </Box>
                        <Box className={'wallet-box'} onClick={() => connectTrustWallet()}>
                            <h5 className={classes.walletLabel}>Trust wallet</h5>
                            <div className={classes.iconBox}>
                                <img src={TrustIcon} alt="" className={classes.walletIcon}/>
                            </div>
                        </Box>
                        <Box className={'wallet-box'} onClick={() => connectWalletConnect()}>
                            <h5 className={classes.walletLabel}>WalletConnect</h5>
                            <div className={classes.iconBox}>
                                <img src={Wlconnect} alt="" className={classes.walletIcon}/>
                            </div>
                        </Box>
                        <Box className={'wallet-box'} onClick={() => connectCryptoDefiWallet()}>
                            <h5 className={classes.walletLabel}>Crypto.com DeFi Wallet</h5>
                            <div className={classes.iconBox}>
                                <img src={CryptoIcon} alt="" className={classes.walletIcon}/>
                            </div>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}