import React, { useEffect, useState, useRef } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useCallback } from 'react';
import ArrowDownIcon from '../assets/images/arrow-down.svg';
import BscIcon from '../assets/images/bitmap_logo.png';
import CroIcon from '../assets/images/crypto-com-logo.png';
import EthIcon from '../assets/images/eth-icon.svg';
import MaticIcon from '../assets/images/matic-icon.svg';
import LiqIcon from '../assets/images/liquidus_logo_round.png';
import SwapSuccessImage from '../assets/images/swap-success.svg';
import WarningIcon from '../assets/images/warning.png';
import { BUY_LIQ_CONTRACTS, LIQ_BNB_CONTRACT, LIQ_CRO_CONTRACT, LIQ_ETH_CONTRACT, LIQ_MATIC_CONTRACT } from '../abis/constants';
import { getLIQPrice } from '../services/api';
import { cutDecimals, parseBNumber, readableNumber } from '../services/utils';
// import { toast } from 'react-toastify';
import ClipLoader from 'react-spinners/ClipLoader';
import useAnalyticsEventTracker from '../services/useAnalyticsEventTracker';

export default function BuyLiqPopup(props) {
  const { web3, setOpenPopup, userAccount, setOpenWalletPopup } = props;
  const gaEventTracker = useAnalyticsEventTracker('Buy Liq');

  const [step, setStep] = useState(0);
  const [fromNetId, setFromNetId] = useState(56);
  const [liqPrice, setLiqPrice] = useState(0);
  const [buyContract, setBuyContract] = useState(null);
  const [buyContract1, setBuyContract1] = useState(null);
  const [buyContract2, setBuyContract2] = useState(null);
  const [contractInfo, setContractInfo] = useState(null);
  const [fromAmount, setFromAmount] = useState('');
  const [fromAmountWithBuffer, setFromAmountWithBuffer] = useState(0);
  const [amountIn, setAmountIn] = useState(0);
  const [toAmount, setToAmount] = useState('');
  const [amountOut, setAmountOut] = useState(0);
  const [focusCode, setFocusCode] = useState(0); // 0 | 1
  const [isInLiq, setIsInLiq] = useState(false);
  const [poolInfo, setPoolInfo] = useState({});
  const [priceImpact, setPriceImpact] = useState(0);
  const [buyLoading, setBuyLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [approved, setApproved] = useState(false);
  const [balance, setBalance] = useState(0);
  const [liqBalance, setLiqBalance] = useState(0);

  const decimals = 18;
  const gasFee = 0.00116322;
  const netInfo = {
    56: { name: 'BNB', label: 'Binance Smart Chain', image: BscIcon, netId: 56 },
    25: { name: 'CRO', label: 'Cronos', image: CroIcon, netId: 25 },
    1: { name: 'ETH', label: 'Ethereum', image: EthIcon, netId: 1 },
    137: { name: 'MATIC', label: 'Matic', image: MaticIcon, netId: 137 },
  };

  let fromRef = useRef(0);
  let toRef = useRef(0);

  const initProcess = useCallback(async () => {
    if (!web3) return;

    try {
      setLoading(true);
      const networkId = await web3?.eth?.net?.getId();
      setFromNetId(networkId);

      const chain = BUY_LIQ_CONTRACTS[networkId];
      setContractInfo(chain);
      const buyLiqContract1 = new web3.eth.Contract(chain.abi, chain.address1);
      setBuyContract1(buyLiqContract1);
      if (networkId !== 1 && networkId !== 137) {
        const buyLiqContract2 = new web3.eth.Contract(chain.abi, chain.address2);
        setBuyContract2(buyLiqContract2);
      }

      if (userAccount) {
        const balanceOf = await web3?.eth?.getBalance(userAccount);
        setBalance(parseBNumber(balanceOf, decimals));

        const tokenContract = new web3.eth.Contract(chain.tokenAbi, chain.tokenAddress);
        const balance_liq = await tokenContract.methods.balanceOf(userAccount).call();
        const liq_balance = parseBNumber(balance_liq, decimals);
        setLiqBalance(liq_balance);
      }

      const liq_price = await getLIQPrice();
      setLiqPrice(liq_price);

      const lpToken = networkId === 56 ? LIQ_BNB_CONTRACT : networkId === 1 ? LIQ_ETH_CONTRACT: networkId === 25 ? LIQ_CRO_CONTRACT : LIQ_MATIC_CONTRACT;
      const lpTokenContract = new web3.eth.Contract(lpToken.abi, lpToken.address);
      const assets = await lpTokenContract.methods.getReserves().call();
      var liqToken = parseBNumber(assets._reserve1, decimals);
      var secondToken = parseBNumber(assets._reserve0, decimals);
      if (networkId === 1) {
        liqToken = parseBNumber(assets._reserve0, decimals);
        secondToken = parseBNumber(assets._reserve1, decimals);
      }
      setPoolInfo({
        liqToken,
        secondToken,
        constantProduct: liqToken * secondToken
      });

      // const gasPrice = await web3.eth.getGasPrice();
      // console.log('gasPrice: ', gasPrice)
      // const latestBlock = await web3.eth.getBlock('latest');
      // const gasLimit = latestBlock.gasLimit;
      // console.log('gasLimit: ', gasLimit)
      // const gasfee = await web3.eth.estimateGas({
      //     from: userAccount,
      //     to: '0x10ED43C718714eb63d5aA57B78B54704E256024E',
      //     amount: web3.utils.toWei('0.00934134', 'ether')
      // })
      // console.log('gasfee: ', gasfee)

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, [web3, userAccount])

  useEffect(() => {
    initProcess();
  }, [initProcess])

  const getPriceImpact = (amount) => {
    const marketRatio = poolInfo.secondToken / poolInfo.liqToken;
    const newSecondToken = poolInfo.secondToken + amount;
    const newLiq = poolInfo.constantProduct / newSecondToken;
    const received = poolInfo.liqToken - newLiq;
    const secondTokenPerLiq = amount / received;
    const price_impact = (secondTokenPerLiq / marketRatio - 1) * 100;
    return price_impact;
  }

  const buyLiq = async () => {
    if (buyLoading) return;
    if (!userAccount) return;
    if (!buyContract) return;
    if (fromAmountWithBuffer > balance || fromAmountWithBuffer <= 0.0) return;
    try {
      setStep(1);
      setBuyLoading(true);

      if (isInLiq) {
        buyContract.methods.swapETHForExactTokens(
          amountOut,
          contractInfo.pathArray,
          userAccount,
          Math.floor((new Date().getTime() + 1200000) / 1000)
        )
          .send({ from: userAccount, value: amountIn })
          .on('receipt', () => {
            gaEventTracker('successfull');
            setBuyLoading(false);
            setStep(2);
          })
          .on('error', (err) => {
            setStep(0);
            console.log(err.code);
            if (err.code === 4001) {
              setStep(0);
            } else {
              setStep(3);
            }
            setBuyLoading(false);
          });
      } else {
        buyContract.methods.swapExactETHForTokens(
          amountOut,
          contractInfo.pathArray,
          userAccount,
          Math.floor((new Date().getTime() + 1200000) / 1000)
        )
          .send({ from: userAccount, value: amountIn })
          .on('receipt', () => {
            gaEventTracker('successfull');
            setBuyLoading(false);
            setStep(2);
          })
          .on('error', (err) => {
            setStep(0);
            console.log(err.code);
            if (err.code === 4001) {
              setStep(0);
            } else {
              setStep(3);
            }
            setBuyLoading(false);
          });
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleFromToken = async (fromStr) => {
    if (loading) return;

    setIsInLiq(false);
    const from = fromStr.replace(',', '.');
    const reg = /^\d+\.?\d*$/;

    if (fromStr === '') {
      setFromAmount('');
      setFromAmountWithBuffer(0);
      setAmountIn(0.0);
      setAmountOut(0.0);
      fromRef.current = '';
      setToAmount('');
      setPriceImpact(0);
    } else if (reg.test(from)) {
      fromRef.current = fromStr;
      setFromAmount(fromStr);
      setFromAmountWithBuffer(parseFloat(from) + gasFee);
      const amount_in = web3.utils.toWei(from, 'ether');
      setAmountIn(amount_in);
      const price_impact = getPriceImpact(parseFloat(from));
      setPriceImpact(price_impact);
      let amount_out_parse1 = 0;
      let amount_out_parse2 = 0;
      try {
        const amount_out1 = await buyContract1.methods.getAmountsOut(
          amount_in,
          contractInfo.pathArray
        ).call();
        amount_out_parse1 = parseBNumber(amount_out1[1], decimals);
      } catch (err) {
        amount_out_parse1 = 0;
        console.log(err);
      }
      if (buyContract2) {
        try {
          const amount_out2 = await buyContract2.methods.getAmountsOut(
            amount_in,
            contractInfo.pathArray
          ).call();
          amount_out_parse2 = parseBNumber(amount_out2[1], decimals);
        } catch (err) {
          amount_out_parse2 = 0;
          console.log(err);
        }
        setBuyContract(amount_out_parse1 > amount_out_parse2 ? buyContract1 : buyContract2);
      } else {
        setBuyContract(buyContract1);
        amount_out_parse2 = 0;
      }
      const amount_out_parse = Math.max(amount_out_parse1, amount_out_parse2);
      // const amount_out = price_impact > 1 ? amount_out_parse * (1 - (price_impact + 0.1) / 100) : amount_out_parse * 0.99;
      const amount_out = amount_out_parse * 0.995;
      const amount_out_min = web3.utils.toWei(amount_out.toFixed(18), 'ether');
      setAmountOut(amount_out_min);
      setToAmount(fromRef.current ? cutDecimals(amount_out_parse, 4).toString() : '');
    }
  }

  const handleToToken = async (toStr) => {
    if (loading) return;

    setIsInLiq(true);
    const to = toStr.replace(',', '.');
    const reg = /^\d+\.?\d*$/;

    if (toStr === '') {
      setToAmount('')
      setAmountOut(0.0);
      setAmountIn(0.0);
      toRef.current = '';
      setFromAmount('');
      setFromAmountWithBuffer(0);
      setPriceImpact(0);
    } else if (reg.test(to)) {
      toRef.current = toStr;
      setToAmount(toStr);
      const amount_out = web3.utils.toWei(to, 'ether');
      setAmountOut(amount_out);

      let amount_in_parse1 = 0;
      let amount_in_parse2 = 0;
      try {
        const amount_in1 = await buyContract1.methods.getAmountsIn(
          amount_out,
          contractInfo.pathArray
        ).call();
        amount_in_parse1 = parseBNumber(amount_in1[0], decimals);
      } catch (err) {
        amount_in_parse1 = 1000000000000000;
        console.log(err);
      }

      if (buyContract2) {
        try {
          const amount_in2 = await buyContract2.methods.getAmountsIn(
            amount_out,
            contractInfo.pathArray
          ).call();
          amount_in_parse2 = parseBNumber(amount_in2[0], decimals);
        } catch (err) {
          amount_in_parse2 = 1000000000000000;
          console.log(err);
        }
        setBuyContract(amount_in_parse1 < amount_in_parse2 ? buyContract1 : buyContract2);
      } else {
        setBuyContract(buyContract1);
        amount_in_parse2 = 1000000000000000;
      }

      const amount_in_parse = Math.min(amount_in_parse1, amount_in_parse2);
      const price_impact = getPriceImpact(amount_in_parse);
      setPriceImpact(price_impact);
      // const amount_in = price_impact > 1 ? amount_in_parse * (1 + (price_impact + 0.1) / 100) : amount_in_parse * 1.01;
      const amount_in = amount_in_parse * 1.005;
      const amount_in_max = web3.utils.toWei(amount_in.toFixed(18), 'ether');
      setAmountIn(amount_in_max);
      setFromAmount(toRef.current ? cutDecimals(amount_in_parse, 4).toString() : '');
      setFromAmountWithBuffer(amount_in + gasFee);
    }
  }


  const connectWallet = () => {
    setOpenWalletPopup(true);
    setOpenPopup(false);
  }

  return (
    <div className='bg-overlay flex-center'>
      <div className='popup-wrapper bridge-wrapper'>
        <div className='popup-box' style={{ overflow: 'hidden' }}>
          <div className='p-4 flex-end'>
            <IconButton
              aria-label="close"
              onClick={() => setOpenPopup(false)}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className='px-5 pb-6 bridge-body position-relative'>
            {step === 0 && (
              <>
                <div className='flex-between mb-3'>
                  <h4>From</h4>
                  <h4>Balance: {cutDecimals(balance, 4)}</h4>
                </div>
                <div className='flex-center mb-2'>
                  <div
                    className='selectmenu flex'
                    style={{ borderColor: focusCode === 1 ? '#9aa6cf' : '#17E7D6' }}
                  >
                    <img src={netInfo?.[fromNetId]?.image || ''} alt="" className='bridge-icon mr-4' />
                    <span className='text-18 mr-3 gray'>{netInfo?.[fromNetId]?.name}</span>
                    <input
                      type="text"
                      value={fromAmount}
                      onChange={(e) => handleFromToken(e.target.value)}
                      placeholder='0.00'
                      style={{ width: 160 }}
                      onFocus={() => setFocusCode(0)}
                    />
                  </div>
                </div>
                <h6 className='mb-10 ml-6 light-gray'>= ${readableNumber(toAmount === '' ? 0 : parseFloat(toAmount.replace(',', '.')) * liqPrice)}</h6>

                <div className='mb-10 text-center'><img src={ArrowDownIcon} alt="" style={{ height: 16 }} /></div>

                <div className='flex-between mb-3'>
                  <h4>To</h4>
                  <h4 className='light-gray'>Balance: {liqBalance.toFixed(2)}</h4>
                </div>
                <div className='flex-center mb-2'>
                  <div
                    className='selectmenu flex'
                    style={{ borderColor: focusCode === 0 ? '#9aa6cf' : '#17E7D6' }}
                  >
                    <img src={LiqIcon} alt="" className='bridge-icon mr-4' />
                    <span className='text-18 mr-3 gray'>LIQ</span>
                    <input
                      type="text"
                      value={toAmount}
                      onChange={(e) => handleToToken(e.target.value)}
                      placeholder='0.00'
                      style={{ width: 160 }}
                      onFocus={() => setFocusCode(1)}
                    />
                  </div>
                </div>
                <h6 className='mb-20 ml-6 light-gray'>= ${readableNumber(toAmount === '' ? 0 : parseFloat(toAmount.replace(',', '.')) * liqPrice)}</h6>

                {priceImpact > 1 && (
                  <div className='position-absolute' style={{ width: '100%', bottom: 140, left: 0 }}>
                    <div className='flex-between position-absolute' style={{ width: 240, margin: '0 50%', transform: 'translateX(-50%)' }}>
                      <p className='text-12 text-bold'>Price Impact High</p>
                      <p className='text-12 text-bold' style={{ color: '#ff0000' }}>{priceImpact.toFixed(2)}%</p>
                    </div>
                  </div>
                )}

                {userAccount ? (
                  <>
                    <div className='flex-center'>
                      <div
                        className='btn-normal mb-4 text-center'
                        style={{
                          width: 180,
                          // padding: '13px 62px',
                          opacity: (loading || fromAmountWithBuffer > balance || fromAmountWithBuffer <= 0.0) ? 0.3 : 1
                        }}
                        onClick={() => buyLiq()}
                      >
                        {fromAmountWithBuffer > balance
                          ? `Insufficient ${netInfo?.[fromNetId]?.name}`
                          : 'Buy LIQ'
                        }
                      </div>
                    </div>
                    <p className='text-center'>Confirm transaction in your wallet</p>
                  </>
                ) : (
                  <div className='flex-center'>
                    <div
                      className='btn-normal mb-4 text-center'
                      style={{ padding: '13px 62px', opacity: loading ? 0.5 : 1 }}
                      onClick={() => connectWallet()}
                    >
                      Connect Wallet
                    </div>
                  </div>
                )}
              </>
            )}
            {step === 1 && (
              <>
                <h4 className='mb-12 text-center'>
                  {`Swapping ${netInfo?.[fromNetId]?.['name']} to LIQ`}
                </h4>
                <div className='flex-center mb-12'>
                  <ClipLoader color={'#17E7D6'} loading={buyLoading} size={70} />
                </div>
                <div style={{ padding: '0 28px' }}>
                  <h2 className='text-18 primary mt-16 mb-7-5' style={{ fontWeight: 700 }}>Swapping tokens</h2>
                  <div className='mb-3'>
                    <div className='flex'>
                      <img src={netInfo?.[fromNetId]?.image || ''} alt="" className='bridge-icon mr-4' />
                      <span className='text-18 mr-3 gray text-bold'>{netInfo?.[fromNetId]?.name}</span>
                      <span className='text-18 primary text-bold'>{fromAmount.replace(',', '.')}</span>
                    </div>
                  </div>
                  <div className='mb-3 px-3'>
                    <img src={ArrowDownIcon} alt="" style={{ height: 16 }} />
                  </div>
                  <div className='mb-4'>
                    <div className='flex'>
                      <img src={LiqIcon} alt="" className='bridge-icon mr-4' />
                      <span className='text-18 mr-3 gray text-bold'>LIQ</span>
                      <span className='text-18 primary text-bold'>{toAmount.replace(',', '.')}</span>
                    </div>
                  </div>
                  <h6 className='mb-10 light-gray'>= ${readableNumber(parseFloat(toAmount.replace(',', '.')) * liqPrice)}</h6>
                </div>
                <div className='text-center'>
                  <div className='flex-center mb-7-5 mt-4'>
                    <div className='btn-close' onClick={() => setOpenPopup(false)}>Close</div>
                  </div>
                </div>
              </>
            )}
            {step === 2 && (
              <>
                <h4 className='mb-12 text-center'>
                  Swap Successful
                </h4>
                <div className='flex-center mb-12'>
                  <img src={SwapSuccessImage} alt="" style={{ width: 115 }} />
                </div>
                <div style={{ padding: '0 28px' }}>
                  <h2 className='text-18 primary mt-16 mb-7-5' style={{ fontWeight: 700 }}>Swapping tokens</h2>
                  <div className='mb-3'>
                    <div className='flex'>
                      <img src={netInfo?.[fromNetId]?.image || ''} alt="" className='bridge-icon mr-4' />
                      <span className='text-18 mr-3 gray text-bold'>{netInfo?.[fromNetId]?.name}</span>
                      <span className='text-18 primary text-bold'>{fromAmount.replace(',', '.')}</span>
                    </div>
                  </div>
                  <div className='mb-3 px-3'>
                    <img src={ArrowDownIcon} alt="" style={{ height: 16 }} />
                  </div>
                  <div className='mb-4'>
                    <div className='flex'>
                      <img src={LiqIcon} alt="" className='bridge-icon mr-4' />
                      <span className='text-18 mr-3 gray text-bold'>LIQ</span>
                      <span className='text-18 primary text-bold'>{toAmount.replace(',', '.')}</span>
                    </div>
                  </div>
                  <h6 className='mb-10 light-gray'>= ${readableNumber(parseFloat(toAmount.replace(',', '.')) * liqPrice)}</h6>
                </div>
                <div className='text-center'>
                  <div className='flex-center mb-7-5 mt-4'>
                    <div className='btn-normal' style={{ width: 180 }} onClick={() => setOpenPopup(false)}>Done</div>
                  </div>
                </div>
              </>
            )}
            {step === 3 && (
              <>
                <div className='flex-center mb-6'>
                  <img src={WarningIcon} alt="" style={{ width: 30, marginRight: 12 }} />
                  <h4>Transaction Failed</h4>
                </div>
                <div className='text-12 light-gray px-7 mb-9'>
                  Don’t worry, transactions can occasionally fail. For example, if the price change is too big while the transaction gets processed by the blockchain.
                  <br /><br />
                  Please confirm the swap again.
                </div>

                <div style={{ padding: '0 28px' }}>
                  <h2 className='text-18 primary mt-16 mb-7-5' style={{ fontWeight: 700 }}>Swapping tokens</h2>
                  <div className='mb-3'>
                    <div className='flex'>
                      <img src={netInfo?.[fromNetId]?.image || ''} alt="" className='bridge-icon mr-4' />
                      <span className='text-18 mr-3 gray text-bold'>{netInfo?.[fromNetId]?.name}</span>
                      <span className='text-18 primary text-bold'>{fromAmount.replace(',', '.')}</span>
                    </div>
                  </div>
                  <div className='mb-3 px-3'>
                    <img src={ArrowDownIcon} alt="" style={{ height: 16 }} />
                  </div>
                  <div className='mb-4'>
                    <div className='flex'>
                      <img src={LiqIcon} alt="" className='bridge-icon mr-4' />
                      <span className='text-18 mr-3 gray text-bold'>LIQ</span>
                      <span className='text-18 primary text-bold'>{toAmount.replace(',', '.')}</span>
                    </div>
                  </div>
                  <h6 className='mb-10 light-gray'>= ${readableNumber(parseFloat(toAmount.replace(',', '.')) * liqPrice)}</h6>
                </div>
                <div className='text-center'>
                  <div className='flex-center mb-7-5 mt-4'>
                    <div className='btn-normal' style={{ width: 180 }} onClick={() => setStep(0)}>Try Again</div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}