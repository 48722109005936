import React, {useEffect, useState, useCallback} from 'react'
import { Grid, Box } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ChevronUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LiquidusLogo from '../assets/images/liquidus_logo_round.png';
import BitmapLogo from '../assets/images/bitmap_logo.png';
import BusdLogo from '../assets/images/busd.svg';
import EthLogo from '../assets/images/eth-icon.svg';
import CroLogo from '../assets/images/crypto-com-logo.png';
import MaticLogo from '../assets/images/matic-icon.svg';
import DetailIcon from '../assets/images/copy_icon.png';
import QuestionIcon from '../assets/images/question.png';
import { parseBNumber, parseDateTime, readableNumber } from '../services/utils';
// import { TOKEN_ABI } from '../abis/constants';
import { getLIQPrice } from '../services/api';
import { COMPOUNDING_PERIOD } from '../services/config';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../redux/common.redux';
import Spinner from './Spinner';

export default function TokenItem(props) {
    const { contract, web3, handleStake, userAccount, netId } = props;
    const refreshCnt = useSelector(state => state.common.refreshCount);
    // const chainId = useSelector(state => state.common.chainNetId);
    const dispatch = useDispatch();

    const [farmContractData, setFarmContractData] = useState({});
    const [tokenContractData, setTokenContractData] = useState({});
    const [tokenID, setTokenID] = useState('');
    const [rewardTokenID, setRewardTokenID] = useState('');
    const [showDetail, setShowDetail] = useState(false);
    const [approved, setApproved] = useState(false);
    const [liqStaked, setLiqStaked] = useState(0);
    const [liqEarned, setLiqEarned] = useState(0);
    // const [userAccount, setUserAccount] = useState('');
    const [decimals, setDecimals] = useState(0);
    const [liquidity, setLiquidity] = useState(0);
    const [stakedLIQ, setStakedLIQ] = useState(0);
    const [stakedBnb, setStakedBnb] = useState(0);
    const [stakedETH, setStakedETH] = useState(0);
    const [stakedCro, setStakedCro] = useState(0);
    const [stakedMATIC, setStakedMATIC] = useState(0);
    const [stakedBusd, setStakedBusd] = useState(0);
    const [oneLiqPrice, setOneLiqPrice] = useState(0);
    const [oneLpPrice, setOneLpPrice] = useState(0);
    const [apr, setApr] = useState(0);
    const [apy, setApy] = useState(0);
    const [nextWithdraw, setNextWithdraw] = useState(0);
    const [loadingApprove, setLoadingApprove] = useState(false);
    const [loadingHarvest, setLoadingHarvest] = useState(false);

    const connectSmartContract = useCallback(async () => {
        if (!web3) return;
        try {
            const farmContract = new web3.eth.Contract(contract.abi, contract.address);
            setFarmContractData(farmContract);
            const tokenAddress = contract.type === 'LP' ? contract.lpSwapToken : contract.stakedToken;
            setTokenID(tokenAddress);
            setRewardTokenID(contract.rewardToken);
    
            const tokenContract = new web3.eth.Contract(contract.tokenAbi, tokenAddress);
            setTokenContractData(tokenContract);
            
            const decimal = await tokenContract.methods.decimals().call();
            setDecimals(decimal);
    
            // const bnbPrice = await getBNBPrice();
            const liqPrice = await getLIQPrice();
            setOneLiqPrice(liqPrice);

            // const net_id = localStorage.getItem('netId');
            // const net_id = await web3.eth.net.getId();
            const blocktime = netId === 25 ? 5.8 : netId === 1 ? 12.2 : netId === 137 ? 2.1 : 3;
    
            if (contract.type === 'LP') {
                const balance = await tokenContract.methods.balanceOf(contract.address).call();
                const cakeLp = parseBNumber(balance, decimal); // LP staked

                const total = await tokenContract.methods.totalSupply().call(); // total LP
                const totalSupply = parseBNumber(total, decimal);
                if (totalSupply <= 0) return;
    
                // get reserves on the token
                const reserves = await tokenContract?.methods?.getReserves().call();
                let totalLiq = 0;
                let totalBnb = 0;
                let totalBusd = 0;
                let totalCro = 0;
                let totalEth = 0;
                let totalMATIC = 0;
                if (contract.name === 'LIQ - BNB') {
                    totalLiq = parseBNumber(reserves._reserve1, decimal); // total LIQ
                    totalBnb = parseBNumber(reserves._reserve0, decimal); // total BNB
                } else if (contract.name === 'LIQ - BUSD') {
                    totalLiq = parseBNumber(reserves._reserve0, decimal); // total LIQ
                    totalBusd = parseBNumber(reserves._reserve1, decimal); // total BUSD
                } else if (contract.name === 'LIQ - CRO') {
                    totalLiq = parseBNumber(reserves._reserve1, decimal); // total LIQ
                    totalCro = parseBNumber(reserves._reserve0, decimal); // total CRO
                } else if (contract.name === 'LIQ - ETH') {
                    totalLiq = parseBNumber(reserves._reserve0, decimal); // total LIQ
                    totalEth = parseBNumber(reserves._reserve1, decimal); // total ETH
                } else if (contract.name === 'LIQ - MATIC') {
                    totalLiq = parseBNumber(reserves._reserve1, decimal); // total LIQ
                    totalMATIC = parseBNumber(reserves._reserve0, decimal); // total MATIC
                }
                setStakedLIQ(totalLiq * cakeLp / totalSupply);
                setStakedBnb(totalBnb * cakeLp / totalSupply);
                setStakedBusd(totalBusd * cakeLp / totalSupply);
                setStakedCro(totalCro * cakeLp / totalSupply);
                setStakedETH(totalEth * cakeLp / totalSupply);
                setStakedMATIC(totalMATIC * cakeLp / totalSupply);

                const oneLP = totalLiq * liqPrice * 2 / totalSupply; // one LP price
                setOneLpPrice(oneLP);
                const cakeLpPrice = cakeLp * oneLP;
                setLiquidity(cakeLpPrice);

                const rewardCnt = await farmContract.methods.rewardPerBlock().call();
                const rewardPerBlock = parseBNumber(rewardCnt, decimal);
                const rewardPerYear = (365 * 24 * 3600 / blocktime) * rewardPerBlock * liqPrice;
                const anualPercentReward = cakeLpPrice > 0 ? (rewardPerYear / cakeLpPrice) * 100 : 0;
                setApr(anualPercentReward);
                const anualPercentYield = (1 + (anualPercentReward / 100) / COMPOUNDING_PERIOD) ** COMPOUNDING_PERIOD - 1
                setApy(readableNumber(anualPercentYield * 100));
            } else if (contract.type === 'LIQ') {
                const response = await farmContract.methods.stakedTokenSupply().call();
                const totalLIQ = parseBNumber(response, decimal);
                setStakedLIQ(totalLIQ);
                setLiquidity(totalLIQ * liqPrice);
                const rewardCnt = await farmContract.methods.rewardPerBlock().call();
                const rewardPerBlock = parseBNumber(rewardCnt, decimal);
                const rewardPerYear = (365 * 24 * 3600 / blocktime) * rewardPerBlock;
                const anualPercentReward = totalLIQ > 0 ? (rewardPerYear / totalLIQ) * 100 : 0;
                setApr(anualPercentReward);
                const anualPercentYield = (1 + (anualPercentReward / 100) / COMPOUNDING_PERIOD) ** COMPOUNDING_PERIOD - 1;
                setApy(readableNumber(anualPercentYield * 100));
            }
            
            if (!userAccount) {
                setApproved(false);
                return;
            }
            
            const userInfo = await farmContract.methods.userInfo(userAccount).call();
            const staked = parseBNumber(userInfo?.amount || 0, decimal);
            setLiqStaked(staked);
    
            const pendingReward = await farmContract.methods.pendingReward(userAccount).call();
            const earned = parseBNumber(pendingReward, decimal);
            setLiqEarned(earned);
    
            const allowance = await tokenContract.methods.allowance(userAccount, contract.address).call();
            setApproved(allowance > 0);

            const vestingTimeInBigNumber = await farmContract.methods.vestingTime().call();
            const vestingTime = Number(vestingTimeInBigNumber);
            if (contract.vesting && userInfo?.lastDepositedAt > 0) {
                // const vestingTime = contract.vesting * 2629746;
                const releaseTime = (parseInt(userInfo?.lastDepositedAt) + vestingTime) * 1000; // in milisecond
                setNextWithdraw(releaseTime);
            }
        } catch (err) {
            console.log(err);
        } 
    }, [web3, contract, userAccount, netId])

    useEffect(() => {
        connectSmartContract();
    }, [refreshCnt, connectSmartContract])

    useEffect(() => {
        const interval = setInterval(() => {
            connectSmartContract();
        }, 10000);

        return () => clearInterval(interval);
    }, [connectSmartContract])

    const handleApproved = async () => {
        if (!web3) {
            alert('No wallet connected');
            return;
        };

        try {
            setLoadingApprove(true);
            // await window.ethereum.enable();
            await tokenContractData?.methods?.approve(
                contract.address,
                '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'
            ).send({ 
                from: userAccount,
                maxPriorityFeePerGas: null,
                maxFeePerGas: null, 
              });

            setLoadingApprove(false);
            setApproved(true);
            
        } catch(err) {
            setLoadingApprove(false);
            console.log(err);
        }
    }

    const handleHarvest = async () => {
        if (!web3) {
            alert('No wallet connected');
            return;
        };
        try {
            setLoadingHarvest(true);
            if (contract.type === 'LP') {
                await farmContractData?.methods?.harvest(userAccount)?.send({ 
                    from: userAccount,
                    maxPriorityFeePerGas: null,
                    maxFeePerGas: null, 
                  });
            } else {
                await farmContractData?.methods?.harvest()?.send({ 
                    from: userAccount,
                    maxPriorityFeePerGas: null,
                    maxFeePerGas: null, 
                  });
            }

            setLoadingHarvest(false);
            dispatch(actions.changeRefresh());
        } catch(err) {
            setLoadingHarvest(false);
            console.log(err)
        }
    }

    return (
        <>
        <Box className='chain-wrapper'>
            <Box className='chain-box' onClick={() => setShowDetail(!showDetail)}>
                <Grid className='main-info'>
                    <Grid container alignItems={'center'} className='main-part'>
                        <Grid item xs={12} md={6}>
                            <Grid container alignItems={'center'} className='title-wrapper'>
                                <Grid item className='logo-wrapper-1'>
                                    <img src={LiquidusLogo} alt="liquidus-logo" className='coin-logo' />
                                </Grid>
                                
                                <Grid item className='logo-wrapper-2'>
                                    {contract.name === 'LIQ - BNB' ? (
                                        <img src={BitmapLogo} alt="bitmap-logo" className='coin-logo' />
                                    ) : contract.name === 'LIQ - BUSD' ? (
                                        <img src={BusdLogo} alt="bitmap-logo" className='coin-logo' />
                                    ) : contract.name === 'LIQ - CRO' ? (
                                        <img src={CroLogo} alt="bitmap-logo" className='coin-logo' />
                                    ) : contract.name === 'LIQ - ETH' ? (
                                        <img src={EthLogo} alt="bitmap-logo" className='coin-logo' />
                                    ) : contract.name === 'LIQ - MATIC' ? (
                                        <img src={MaticLogo} alt="bitmap-logo" className='coin-logo' />
                                    ) : <div className='coin-logo' />}
                                </Grid>
                                
                                <Grid item style={{paddingLeft: 30}}>
                                    <div className='name-box'>
                                        <h5 className='title'>{contract.name}</h5>
                                        {/* {contract.name === 'LIQ - BUSD' && (
                                            <span className='new-badge'>Boosted</span>
                                        )} */}
                                    </div>
                                    {contract.type === 'LP' && (
                                        <h6 className='coin-label'>{contract.title}</h6>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container alignItems={'center'}>
                                <Grid item xs>
                                    <h6 className='coin-label'>{'EARNED'}</h6>
                                    {decimals > 0 && (
                                        <h6 className='coin-value'>{liqEarned.toFixed(3)} LIQ</h6>
                                    )}
                                </Grid>
                                <Grid item xs>
                                    <h6 className='coin-label'>{'APY'}</h6>
                                    <h6 className='coin-value'>{apy}%</h6>
                                </Grid>
                                <Grid item xs>
                                    <h6 className='coin-label'>{'Vesting'}</h6>
                                    <h6 className='coin-value'>{contract.vesting ? `${contract.vesting} Month` : 'None'}</h6>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item className='arrow-part'
                        // onClick={() => handleClickDrop(idx)}
                    >
                        <Box sx={{display: {xs: 'block', sm: 'none'}}}>
                            {showDetail
                                ? <ChevronUpIcon className='arrow-icon' style={{fontSize: 34}}/>
                                : <ChevronDownIcon className='arrow-icon' style={{fontSize: 34}}/>
                            }
                        </Box>
                        <Box sx={{display: {xs: 'none', sm: 'block'}}}>
                            {showDetail
                                ? <ChevronDownIcon className='arrow-icon' style={{fontSize: 34}}/>
                                : <ChevronRightIcon className='arrow-icon' style={{fontSize: 34}}/>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            
            {showDetail && (
                <>
                    <Box className='border my-8' sx={{display: {xs: 'none', sm: 'block'}}} />
                    <Grid container className='detail-info'>
                        <Grid item xs={12} md={4} className='detail-wrapper'>
                            <Box className='border detail-box mb-3'>
                                <Grid container>
                                    <Grid item xs={7}>
                                        <h6 className='coin-label pb-2'>
                                            {contract.type === 'LP' ? `${contract.name.split(' ').join('')} LP STAKED` : 'LIQ STAKED'}
                                        </h6>
                                        {decimals > 0 && (
                                            <>
                                            <h6 className='coin-value'>{liqStaked.toFixed(3)}</h6>
                                            <h6 className='text-small'>$ {contract.type === 'LP' ? (liqStaked * oneLpPrice).toFixed(2) : (liqStaked * oneLiqPrice).toFixed(2)}</h6>
                                            </>
                                        )}
                                        {contract.vesting && nextWithdraw > 0 && (
                                            <h6 className='info-text'>{`Next withdraw: ${parseDateTime(nextWithdraw)}`}</h6>
                                        )}
                                    </Grid>
                                    <Grid item xs={5} className='pt-2 pb-6'>
                                        {!approved ? (
                                            <Grid container justifyContent={'flex-end'} className='btn-wrapper'>
                                                <Grid item>
                                                    <div className='btn-normal flex-center' onClick={() => handleApproved()}>
                                                    {loadingApprove ? (
                                                        <Spinner color='primary'/>
                                                    ) : (
                                                        <span>Approve</span>
                                                    )}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Grid container justifyContent='flex-end'>
                                                <Grid item className='pr-2'>
                                                    <button className='btn-count' onClick={() => handleStake('withdraw', contract)}>-</button>
                                                </Grid>
                                                <Grid item>
                                                    <button className='btn-count mr-3' onClick={() => handleStake('stake', contract)}>+</button>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Grid>
                                {contract.vesting && (
                                    <Box className='info'>
                                        <Box><h6 className='info-text pr-2'>{`After you deposit, you can't withdraw for ${contract.vesting} month!`}</h6></Box>
                                        <Box><a href="https://docs.liquidus.finance/liquidus-farm/liq-yield-farming" target='_blank' rel='noreferrer'>
                                            <img src={QuestionIcon} alt="question-icon"/>
                                        </a></Box>
                                    </Box>
                                )}
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4} className='detail-wrapper'>
                            <Box className='border detail-box mb-6'>
                                <Grid container alignItems='center' className='blank-block'>
                                    <Grid item xs={6}>
                                        <h6 className='coin-label mb-2'>{'LIQ EARNED'}</h6>
                                        {decimals > 0 && (
                                            <>
                                            <h6 className='coin-value'>{liqEarned.toFixed(3)}</h6>
                                            <h6 className='text-small'>$ {(liqEarned * oneLiqPrice).toFixed(2)}</h6>
                                            </>
                                        )}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container justifyContent='flex-end' className='btn-wrapper'>
                                            <Grid item>
                                                <div className='btn-normal flex-center' onClick={() => handleHarvest()}>
                                                    {loadingHarvest ? (
                                                        <Spinner color='primary'/>
                                                    ) : (
                                                        <span>Harvest</span>
                                                    )}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={4} className='detail-last'>
                            <Box className='flex-between mb-3'>
                                <span className='text-normal'>{'Liquidity'}</span>
                                <span className='text-normal'>${liquidity.toFixed(2)}</span>
                            </Box>
                            {contract.type === 'LIQ' ? (
                                <Box className='flex-between mb-3'>
                                    <span className='text-normal'>{'LIQ Staked'}</span>
                                    <span className='text-normal'>{stakedLIQ.toFixed(2)} LIQ</span>
                                </Box>
                            ) : (
                                <>
                                <Box className='flex-between mb-3'>
                                    <span className='text-normal'>{'LIQ Staked'}</span>
                                    <span className='text-normal'>{stakedLIQ.toFixed(2)} LIQ</span>
                                </Box>
                                <Box className='flex-between mb-3'>
                                    <span className='text-normal'>
                                        {contract.name === 'LIQ - BNB' ? 'BNB Staked' : contract.name === 'LIQ - BUSD' ? 'BUSD Staked' : contract.name === 'LIQ - ETH' ? 'ETH Staked' : contract.name === 'LIQ - CRO' ? 'CRO Staked' : 'MATIC Staked'}
                                    </span>
                                    <span className='text-normal'>
                                        {contract.name === 'LIQ - BNB' ? `${stakedBnb.toFixed(2)} BNB` : contract.name === 'LIQ - BUSD' ? `${stakedBusd.toFixed(2)} BUSD` : contract.name === 'LIQ - ETH' ? `${stakedETH.toFixed(2)} ETH` : contract.name === 'LIQ - CRO' ? `${stakedCro.toFixed(2)} CRO` : `${stakedMATIC.toFixed(2)} MATIC`}
                                    </span>
                                </Box>
                                </>
                            )}
                            
                            <Box className='flex-between mb-3'>
                                <span className='text-normal'>{'APR'}</span>
                                <span className='text-normal'>{apr.toFixed(2)}%</span>
                            </Box>
                            <Box className='mb-2'>
                                <a
                                    className='text-normal text-gray'
                                    href={(contract.title === 'QuickSwap V2' || contract.title === 'Uniswap') ? `${contract.link}` : `${contract.link}${contract.type === 'LP' ? rewardTokenID : tokenID}`}
                                    target='_blank'
                                    rel="noopener noreferrer"
                                >
                                    Get {contract.linkName} &nbsp;
                                    <span><img src={DetailIcon} alt="" className='detail-icon'/></span>
                                </a>
                            </Box>
                            <Box className='text-normal text-gray'>
                                <a
                                    className='text-normal text-gray'
                                    href={contract.contractLink}
                                    target='_blank'
                                    rel="noopener noreferrer"
                                >
                                    View Contract &nbsp;
                                    <span><img src={DetailIcon} alt="" className='detail-icon'/></span>
                                </a>
                            </Box>
                        </Grid>
                    </Grid>
                </>
            )}
        </Box>
        </>
    )
}
