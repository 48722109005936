import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Box } from '@mui/material';
import LiquidusLogo from '../assets/images/liquidus_logo_round.png';
import BnbLogo from '../assets/images/bitmap_logo.png';
import BusdLogo from '../assets/images/busd.svg';
import UsdcLogo from '../assets/images/usdc.svg';
import { makeBNumber, parseBNumber, readableNumber } from '../services/utils';
import { getBNBPrice, getLIQPrice } from '../services/api';
import { COMPOUNDING_PERIOD } from '../services/config';
// import BusdLogo from '../assets/images/busd.svg';

const NFTItem = (props) => {
    const { nft, web3 } = props;
    const { push } = useHistory();

    const [liqAmount, setLiqAmount] = useState(0);
    const [token2ndAmount, setToken2ndAmount] = useState(0);
    const [editon, setEditon] = useState(0);
    const [mintedAmount, setMintedAmount] = useState(0);
    const [apy, setApy] = useState(0);
    const decimals = 18;

    const getNFTInfo = useCallback(async () => {
        if (!web3) return;

        try {
            const bnbPrice = await getBNBPrice();
            const liqPrice = await getLIQPrice();

            const nftContract = new web3.eth.Contract(nft.nftAbi, nft.nftContract);
            const nftInfo = await nftContract.methods.NftInfo(nft.nftID).call();
            const liq = parseBNumber(nftInfo.priceInLIQ, decimals)
            setLiqAmount(liq);

            const token2nd_contract = new web3.eth.Contract(nft.token2ndAbi, nft.token2ndContract);
            const decimal_token2nd = await token2nd_contract.methods.decimals().call();

            const token2nd = nft.token2nd === 'BNB'
                ? parseBNumber(nftInfo.priceInBNB, decimal_token2nd)
                : parseBNumber(nftInfo.priceIn2ndToken, decimal_token2nd);
            setToken2ndAmount(token2nd);
            setEditon(nftInfo.maximumAmount);
            setMintedAmount(nftInfo.mintedAmount);

            /* APR calculation */
            const sumPrice = liq * liqPrice + token2nd * (nft.token2nd === 'BNB' ? bnbPrice : 1);
            const nftStakingContract = new web3.eth.Contract(nft.nftStakingAbi, nft.nftStaking);
            //todo
            // const annualReward = await nftStakingContract.methods.annualReward().call();
            const annualReward = nft.liqRewards
                ? makeBNumber(nft.liqRewards, decimals)
                : await nftStakingContract.methods.annualReward().call();
            const totalReward = parseBNumber(annualReward, decimals);
            const totalRewardPrice = totalReward * liqPrice;
            const apr = (totalRewardPrice) * 100 / sumPrice;
            const anualPercentYield = (1 + (apr / 100) / COMPOUNDING_PERIOD) ** COMPOUNDING_PERIOD - 1;
            setApy(readableNumber(anualPercentYield * 100));
        } catch (err) {
            console.log(err);
        }
    }, [web3, nft])

    useEffect(() => {
        getNFTInfo()
    }, [getNFTInfo])

    useEffect(() => {
        const interval = setInterval(() => {
            getNFTInfo();
        }, 10000);

        return () => clearInterval(interval);
    }, [getNFTInfo])

    return (
        <>
        <Box
            className='nft-card'
            onClick={() => push(`/nft-detail/${nft?.nftID}`)}
        >
            <div
                className='nft-img'
                style={{backgroundImage: `url(${nft?.smallImage})`}}
            />
            <div className='nft-footer'>
                <h4>{nft?.name}</h4>
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end'}}>
                    <div className='nft-info'>
                        <div className='flex-start'>
                            <img src={LiquidusLogo} alt=""/>
                            <span className='liq-label'>{liqAmount} LIQ</span>
                            <img
                                src={nft.token2nd === 'BNB'
                                    ? BnbLogo
                                    : nft.token2nd === 'BUSD'
                                        ? BusdLogo
                                        : nft.token2nd === 'USDC'
                                            ? UsdcLogo
                                            : null
                                }
                                alt=""
                            />
                            <span className={`token-2-label ${nft.token2nd === 'BUSD' ? 'busd-color' : nft.token2nd === 'USDC' ? 'usdc-color' : ''}`}>
                                {`${token2ndAmount} ${nft?.token2nd}`}
                            </span>
                        </div>
                        <p>{`${mintedAmount}/${editon} editions`}</p>
                    </div>
                    <div className='nft-apy'>
                        <div className='nft-badge'>{apy}% APY</div>
                    </div>
                </div>
            </div>
        </Box>
        </>
    )
}

export default NFTItem;