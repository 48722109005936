import Web3 from 'web3';
import { RPC, SUPPORTED_NET } from '../abis/constants';
import * as Metamask from './wallet-metamask';
import * as WalletConnect from './wallet-connect';
import * as Defiwallet from './defiwallet';

export const getDefaultWallet = () => {
  const net_id = localStorage.getItem('netId');
  let netId = net_id ? parseInt(net_id) : 56;
  if (!SUPPORTED_NET.includes(netId)) {
    netId = 56;
    localStorage.setItem('netId', netId);
  }

  return {
    walletProviderName: "",
    address: "",
    wcConnector: null,
    wcProvider: null,
    web3: new Web3(RPC[netId]['url']),
    connected: false,
    networkId: netId,
  }
};

export const getWallet = async () => {
  const net_id = localStorage.getItem('netId') || '56';
  let netId = parseInt(net_id);
  if (!SUPPORTED_NET.includes(netId)) {
    netId = 56;
    localStorage.setItem('netId', netId);
  }

  const walletType = localStorage.getItem('walletType');
    
  if (walletType === 'metamask') {
    return await Metamask.connect();
  } else if (walletType === 'wallet-connect') {
    return await WalletConnect.connect();
  } else if (walletType === 'defi-wallet') {
    return await Defiwallet.connect();
  } else {
    return {
      walletProviderName: "",
      address: "",
      wcConnector: null,
      wcProvider: null,
      web3: new Web3(RPC[netId]['url']),
      connected: false,
      networkId: netId,
      chainId: null,
    }
  }
}

export const disconnectWallet = async () => {
  localStorage.removeItem('walletType');
}