import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, Box } from '@mui/material';
import TokenItem from './TokenItem';
import NFTItem from './NFTItem';
import {
    BSC_CONTRACT_LIST,
    CRONOS_CONTRACT_LIST,
    NFT_LIST,
    CRONOS_NFT_LIST,
    ETH_CONTRACT_LIST,
    MATIC_CONTRACT_LIST,
    NETWORK_INFO
} from '../abis/constants';
import HackenLogo from '../assets/images/hacken-io-logo-vector.svg';
import LiquidusInvertLogo from '../assets/images/logo_inverted.png';
import PotLinkBannerDesktop from '../assets/images/pot_banner_desktop.png';
import PotLinkBannerMobile from '../assets/images/pot_banner_mobile.png';
import BridgeGroup from './BridgeGroup';
import { getLIQPrice } from '../services/api';

const farmDataList = {
    56: BSC_CONTRACT_LIST,
    137: MATIC_CONTRACT_LIST,
    1: ETH_CONTRACT_LIST,
    25: CRONOS_CONTRACT_LIST,
};

export default function TokensPanel(props) {
    const {
        web3,
        account,
        setSelectedContract,
        setSelectedKey,
        setLoading,
        setOpenStakePopup,
        setOpenBridgePopup,
        setOpenBuyPopup,
        netId
    } = props;
    const [farmList, setFarmList] = useState([]);
    const [nftList, setNftList] = useState([]);
    const [liqPrice, setLiqPrice] = useState(0);
    const [showEndedFarms, setShowEndedFarms] = useState(false);
    const networkInfo = NETWORK_INFO[netId]?.[0];
    const showNFTs = netId === 56 || netId === 25;
    const filteredFarmList = useMemo(() => {
        if (showEndedFarms) {
            return farmList.filter((item) => item.rewardPerBlock === 0);
        } else {
            return farmList.filter((item) => item.rewardPerBlock !== 0);
        }
    }, [farmList, showEndedFarms]);

    const updateFarmWithBlocks = useCallback(async (farmData) => {
        const rewardPerBlocksPromises = [];
        for (const contract of farmData) {
          const farmContract = new web3.eth.Contract(
            contract.abi,
            contract.address
          );
          const rewardPerBlockCall = farmContract.methods.rewardPerBlock().call();
          rewardPerBlocksPromises.push(rewardPerBlockCall);
        }
        const data = await Promise.all(rewardPerBlocksPromises);
        const _farmList = farmData.map((item, index) => ({
          ...item,
          rewardPerBlock: Number(data[index]),
          showDetail: false,
          approved: false,
        }));
        setFarmList(_farmList);
    }, [web3]);

    useEffect(() => {
        // const net_id = localStorage.getItem('netId');
        // const netId = net_id ? parseInt(net_id) : 56;
        if (netId) {
            updateFarmWithBlocks(farmDataList[netId]);
            const nfts = netId === 56 ? [...NFT_LIST] : netId === 25 ? [...CRONOS_NFT_LIST] : [];
            setNftList([...nfts]);
        }
    }, [netId, updateFarmWithBlocks])

    useEffect(() => {
        async function get_liq_price() {
            const liqCost = await getLIQPrice();
            setLiqPrice(liqCost || 0);
        }
        get_liq_price();
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [showEndedFarms]);

    const handleStake = (key, contract) => {
        setSelectedKey(key);
        setSelectedContract(contract);
        setOpenStakePopup(true);
    }
    return (
        <>
            <Box sx={{display: {xs: 'block', sm: 'none'}}}>
                <Grid item className='heading-box'>
                    <h3 className='heading'>Yield Farming</h3>
                    <h6 className='sub-heading'>Stake LIQ or LIQ-{networkInfo?.nativeCurrency.symbol} LP tokens to earn.</h6>
                </Grid>
                <BridgeGroup
                    setOpenBridgePopup={setOpenBridgePopup}
                    setOpenBuyPopup={setOpenBuyPopup}
                />
                <div className='flex-center mt-4' style={{marginBottom: '-25px'}}>
                    <div
                        className='btn-normal mr-2 flex'
                        style={{ borderRadius: '100px', paddingLeft: 12, paddingRight: 22, cursor: 'default' }}
                    >
                        {/* <div className='flex'> */}
                            <img src={LiquidusInvertLogo} alt="logo" style={{ width: 30, height: 30 }} />
                            <h6 className='coin-value'>{`LIQ $ ${parseFloat(liqPrice).toFixed(2)}`}</h6>
                        {/* </div> */}
                    </div>
                </div>
            </Box>
            <Grid className='content'>
                <Box sx={{display: {xs: 'none', sm: 'block'}}}>
                    <Grid container justifyContent={'space-between'} alignItems='center' className='heading-box' rowSpacing="24px">
                        <Grid item>
                            <h3 className='heading'>Yield Farming</h3>
                            <h6 className='sub-heading'>Stake LIQ or LIQ-{networkInfo?.nativeCurrency.symbol} LP tokens to earn.</h6>
                        </Grid>
                        <Grid item>
                            <div className='flex'>
                                <div
                                    className='btn-normal mr-2'
                                    style={{ borderRadius: '100px', paddingLeft: 12, paddingRight: 22, cursor: 'default' }}
                                >
                                    <div className='flex-center'>
                                        <img src={LiquidusInvertLogo} alt="logo" style={{ width: 30, height: 30 }} />
                                        <h6 className='coin-value'>{`LIQ $ ${parseFloat(liqPrice).toFixed(2)}`}</h6>
                                    </div>
                                </div>
                                <BridgeGroup
                                    setOpenBridgePopup={setOpenBridgePopup}
                                    setOpenBuyPopup={setOpenBuyPopup}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{display: {xs: 'none', sm: 'block'}, padding: '0 60px 30px'}}>
                    <a href="https://liquidus.app.link/dsmexkJD1tb" target="_blank" rel="noreferrer">
                        <img className='pot-banner' src={PotLinkBannerDesktop} alt="pot-banner" />
                    </a>
                </Box>
                <Box sx={{display: {xs: 'block', sm: 'none'}, padding: '49px 7px 12px'}}>
                    <a href="https://liquidus.app.link/dsmexkJD1tb" target="_blank" rel="noreferrer">
                        <img className='pot-banner' src={PotLinkBannerMobile} alt="pot-banner" />
                    </a>
                </Box>
                <Grid item className='chain-container'>
                    {filteredFarmList.map((item, idx) => {
                        return (
                            <TokenItem
                                key={idx}
                                contract={item}
                                web3={web3}
                                handleStake={handleStake}
                                code={idx}
                                userAccount={account}
                                netId={netId}
                                setLoading={setLoading}
                            />
                        )
                    })}
                </Grid>
                <Box
                    sx={{padding: {xs: '0 7px 16px', md: '0 60px 40px'}, display: 'flex', justifyContent: {xs: 'center', md: 'flex-end'}}}>
                    <Box sx={{cursor: 'pointer'}} onClick={() => setShowEndedFarms((prev) => !prev)}>
                        <h6 style={{color: '#17E7D6'}} className='coin-label'>{showEndedFarms ? 'Active Farms' : 'Finished Farms'}</h6>
                    </Box>
                </Box>
                <div className='flex'>
                    <a
                        href='https://hacken.io/wp-content/uploads/2021/12/Liquidus_20122021SCAudit_Report.pdf'
                        target={'_blank'}
                        rel="noreferrer"
                    >
                        <div className='audited-by'>
                            <p>Audited by</p>
                            <img src={HackenLogo} alt=""/>
                        </div>
                    </a>
                </div>
            </Grid>
            {showNFTs && (
                <Grid className='nft-section'>
                    <Box className='nft-container'>
                        <h3>Mint & Farm Liquidus NFTs</h3>
                        <h6>Earn LIQ tokens at high APYs by minting and farming official Liquidus NFTs.</h6>
                        <Grid container spacing={3}>
                            {nftList.map((item, idx) => {
                                return (
                                    <Grid item xs={12} sm={6} md={4} key={idx}>
                                        <NFTItem
                                            nft={item}
                                            web3={web3}
                                            userAccount={account}
                                        />
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Box>
                </Grid>
            )}
        </>
    )
}