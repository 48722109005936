import React, { useEffect, useState, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import ChevronDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Logo from '../assets/images/liquidus_logo.png';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../redux/wallet.redux';
import Footer from '../components/Footer';
import WalletsPopup from '../components/WalletsPopop';
import SettingPopup from '../components/SettingPopup';
import DropMenu from '../components/DropMenu';
import TokensPanel from '../components/TokensPanel';
import StakeTokenPopup from '../components/StakeTokenPopup';
import Spinner from '../components/Spinner';
import BridgePopup from '../components/BridgePopup';
import BuyLiqPopup from '../components/BuyLiqPopup';
import { getWallet } from '../helper/connect-wallet';
import CoinmarketPopup from '../components/CoinmarketPopup';

export default function Home(props) {
    const location = useLocation();
    const dispatch = useDispatch();
    const wallet = useSelector(state => state.wallet.wallet);
    const refreshCount = useSelector(state => state.common.refreshCount);

    const [netId, setNetId] = useState(0);
    const [web3Client, setWeb3Client] = useState(null);
    const [userAccount, setUserAccount] = useState('');
    const [openWalletPopup, setOpenWalletPopup] = useState(false);
    const [openSettingPopup, setOpenSettingPopup] = useState(false);
    const [openStakePopup, setOpenStakePopup] = useState(false);
    const [openBridgePopup, setOpenBridgePopup] = useState(false);
    const [openBuyPopup, setOpenBuyPopup] = useState(false);
    const [openCmPopup, setOpenCmPopup] = useState(false);
    const [selectedKey, setSelectedKey] = useState('stake');
    const [selectedContract, setSelectedContract] = useState({});
    const [loading, setLoading] = useState(false);

    const connectBlockchain = useCallback(async () => {
        const newWallet = await getWallet();
        dispatch(actions.updateWalletAction(newWallet));
    }, [dispatch])

    useEffect(() => {
        connectBlockchain();
    }, [connectBlockchain, refreshCount])

    useEffect(() => {
        const { web3, address, networkId } = wallet;
        setWeb3Client(web3);
        setUserAccount(address);
        setNetId(networkId);
    }, [wallet]);

    useEffect(() => {
        const path = location.pathname;
        if (path === '/buyliq') {
            setOpenBuyPopup(true);
        }
        if (path === '/bridge') {
            setOpenBridgePopup(true);
        }
        if (path === '/cmcairdrop') {
            setOpenCmPopup(true);
        }
    }, [location.pathname])

    return (
        <Box className='root'>
            <div className='bg-polygon'/>
            <Grid
                container
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                className='header'
            >
                <Grid item className='pb-3'>
                    <a href="http://liquidus.finance">
                        <img src={Logo} alt="logo" className='logo' />
                    </a>
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item>
                            <DropMenu
                                netId={netId}
                            />
                        </Grid>
                        <Grid item style={{position: 'relative'}}>
                            {userAccount ? (
                                <>
                                <button className='btn-round text-btn flex-center' onClick={() => setOpenSettingPopup(true)}>
                                    {userAccount && `${userAccount.slice(0, 6)}...${userAccount.slice(userAccount.length - 4, userAccount.length)}`} &nbsp;
                                    <ChevronDownIcon style={{fontSize: 24, color: '#181F2B'}}/>
                                </button>
                                {openSettingPopup && (
                                    <SettingPopup
                                        setOpenPopup={setOpenSettingPopup}
                                    />
                                )}
                                </>
                            ) : (
                                <button className='btn-round text-btn' onClick={() => setOpenWalletPopup(true)}>
                                    Connect Wallet
                                </button>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid className='main-wrapper'>
                <TokensPanel
                    web3={web3Client}
                    account={userAccount}
                    setSelectedKey={setSelectedKey}
                    setSelectedContract={setSelectedContract}
                    setOpenStakePopup={setOpenStakePopup}
                    setOpenBridgePopup={setOpenBridgePopup}
                    setOpenBuyPopup={setOpenBuyPopup}
                    netId={netId}
                    setLoading={setLoading}
                />
                <Footer/>
            </Grid>
            {openWalletPopup && (
                <WalletsPopup
                    setOpenPopup={setOpenWalletPopup}
                />
            )}
            {openStakePopup && (
                <StakeTokenPopup
                    type={selectedKey}
                    contract={selectedContract}
                    setOpenPopup={setOpenStakePopup}
                    web3={web3Client}
                    userAccount={userAccount}
                    setLoading={setLoading}
                />
            )}
            {openBridgePopup && (
                <BridgePopup
                    setOpenPopup={setOpenBridgePopup}
                    setOpenWalletPopup={setOpenWalletPopup}
                    web3={web3Client}
                    userAccount={userAccount}
                />
            )}

            {openBuyPopup && (
                <BuyLiqPopup
                    setOpenPopup={setOpenBuyPopup}
                    setOpenWalletPopup={setOpenWalletPopup}
                    web3={web3Client}
                    userAccount={userAccount}
                />
            )}

            {openCmPopup && (
                <CoinmarketPopup
                    setOpenPopup={setOpenCmPopup}
                    web3Prop={web3Client}
                    accountProp={userAccount}
                />
            )}

            {loading && (
                <Spinner shape='ellipsis' setLoading={setLoading} />
            )}
        </Box>
    )
}