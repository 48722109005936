import React from 'react';
import BuyIcon from '../assets/images/buy-icon.svg';
import BuyLightIcon from '../assets/images/buy-icon-light.svg';
// import BridgeIcon from '../assets/images/bridge-icon.svg';
// import BridgeLightIcon from '../assets/images/bridge-icon-light.svg';

export default function BridgeGroup(props) {
    const { setOpenBuyPopup } = props;
    return (
        <div className='bridge'>
            <div className='bridge-btn flex-center' onClick={() => setOpenBuyPopup(true)}>
                <img src={BuyIcon} alt="" className='mr-3 desktop'/>
                <img src={BuyLightIcon} alt="" className='mr-3 mobile'/>
                <span>Buy LIQ</span>
            </div>
            {/* <div className='bridge-btn flex-center' onClick={() => setOpenBridgePopup(true)}>
                <img src={BridgeIcon} alt="" className='mr-3 desktop'/>
                <img src={BridgeLightIcon} alt="" className='mr-3 mobile'/>
                <span>Bridge</span>
            </div> */}
        </div>
   )
}