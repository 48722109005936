import React, { useCallback, useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import ChevronDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CronosIcon from '../assets/images/cronos.svg';
import BinanceIcon from '../assets/images/bitmap_logo.png';
import { actions } from '../redux/common.redux';

const useStyles = makeStyles(theme => ({
    overlay: {
        position: 'fixed',
        width: '100%',
        height: '100vh',
        top: 0,
        left: 0,
        zIndex: 1,
        backgroundColor: 'rgba(17, 36, 85, 0)',
    },
}))

const menuList = [
    {name: 'BSC', image: BinanceIcon, netId: 56},
    {name: 'CRONOS', image: CronosIcon, netId: 25},
]

export default function DropMenuPot(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { netId } = props;
    const [menuId, setMenuId] = useState(0);
    const [showMenu, setShowMenu] = useState(false);

    const handleMenu = useCallback((id) => {
        setMenuId(id);
        const net_id = menuList[id]['netId'];
        localStorage.setItem('netId', net_id);
        setShowMenu(false);
        // window.location.reload();
        dispatch(actions.changeRefresh());
    }, [dispatch]);

    useEffect(() => {
        const initMenuId = netId === 56 ? 0 : 1;
        setMenuId(initMenuId);
        // need to be removed after ETH is added.
        if (netId === 1) {
            handleMenu(0);
        }
    }, [handleMenu, netId])
    
    return (
        <>
        <Box sx={{position: 'relative', backgroundColor: "#112455", borderRadius: "100px"}}>
            <div className='dropmenuMedium flex-between' onClick={() => setShowMenu(!showMenu)}>
                <div className='flex-center'>
                    <img src={menuList?.[menuId]?.['image']} alt="" className='mr-3'/>
                    <span>{menuList?.[menuId]?.['name']}</span>
                </div>
                <ChevronDownIcon style={{fontSize: 20, color: '#FFFFFF'}}/>
            </div> 
            {showMenu && (
                <>
                <div className='droplist'>
                    {menuList.map((item, idx) => (
                        <div key={idx} className='dropmenuMedium flex-start' onClick={() => handleMenu(idx)}>
                            <span><img src={item.image} alt="" className='mr-3'/></span>
                            <span>{item.name}</span>
                        </div>
                    ))}
                </div>
                <Box className= {classes.overlay} onClick={() => setShowMenu(false)} />
                </>
            )}
        </Box>
        </>
    )
}