import React, { useEffect, useState, useCallback } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch } from 'react-redux';
import { actions } from '../redux/common.redux';
import Spinner from './Spinner';
import { getCurrentNFTToken, makeBNumber, parseBNumber, readableNumber } from '../services/utils';

import DepositSuccessImage from '../assets/images/deposit-success-image.svg';
import LiqLogo from '../assets/images/liquidus_logo_round.png';
import { getBNBPrice, getLIQPrice } from '../services/api';
import { COMPOUNDING_PERIOD } from '../services/config';

export default function FarmNFTPopup(props) {
    const {setOpenPopup, web3, userAccount, nft, setOpenWalletPopup} = props;
    const dispatch = useDispatch();
    const [nftContract, setNftContract] = useState(null);
    const [minTokenID, setMinTokenID] = useState(null);
    const [approved, setApproved] = useState(false);
    const [stakeContract, setStakeContract] = useState(null);
    const [loadingApprove, setLoadingApprove] = useState(false);
    const [loadingFarm, setLoadingFarm] = useState(false);
    const [initLoading, setInitLoading] = useState(false);
    const [deposited, setDeposited] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [liqRewards, setLiqRewards] = useState(0);
    const [apy, setApy] = useState(0);
    const decimals = 18;
    

    const initProcess = useCallback(async () => {
        if (!web3) return;
        setInitLoading(true);
        try {
            const nft_contract = new web3.eth.Contract(nft.nftAbi, nft.nftContract);
            setNftContract(nft_contract);
            /* Get tokenIDs in user wallet */
            if (userAccount) {
                const tokens = await nft_contract?.methods?.tokensOfHolder(userAccount)?.call() || [];
                const min_token_id = getCurrentNFTToken(tokens, nft.nftID);
                setMinTokenID(min_token_id);

                if (min_token_id) {
                    /* Check if Approved */
                    const is_approved = await nft_contract?.methods?.getApproved(min_token_id)?.call();
                    if (is_approved === nft.nftStaking) {
                        setApproved(true);
                    } else {
                        setApproved(false);
                    }
                }
            }

            const stake_contract = new web3.eth.Contract(nft.nftStakingAbi, nft.nftStaking);
            setStakeContract(stake_contract);

            setInitLoading(false);
        } catch (err) {
            console.log(err);
            setInitLoading(false);
        }
    }, [nft, web3, userAccount])

    useEffect(() => {
        initProcess();
    }, [initProcess])

    const handleApprovedNFT = async () => {
        if (initLoading) return;

        setLoadingApprove(true);
        try {
            await nftContract.methods.approve(nft.nftStaking, minTokenID).send({ 
                from: userAccount, 
                maxPriorityFeePerGas: null,
                maxFeePerGas: null,
              });
            dispatch(actions.changeRefresh());
            setApproved(true);
            setLoadingApprove(false);
        } catch (err) {
            console.log(err);
            setLoadingApprove(false);
        }
    }

    const getContractInfo = async () => {
        const bnbPrice = await getBNBPrice();
        const liqPrice = await getLIQPrice();

        const nftContract = new web3.eth.Contract(nft.nftAbi, nft.nftContract);
        const nftInfo = await nftContract.methods.NftInfo(nft.nftID).call();
        const liq = parseBNumber(nftInfo.priceInLIQ, decimals)

        const token2nd_contract = new web3.eth.Contract(nft.token2ndAbi, nft.token2ndContract);
        const decimal_token2nd = await token2nd_contract.methods.decimals().call();
        const token2nd = nft.token2nd === 'BNB'
            ? parseBNumber(nftInfo.priceInBNB, decimal_token2nd)
            : parseBNumber(nftInfo.priceIn2ndToken, decimal_token2nd);
        
        /* APR calculation */
        const sumPrice = liq * liqPrice + token2nd * (nft.token2nd === 'BNB' ? bnbPrice : 1);
        const nftStakingContract = new web3.eth.Contract(nft.nftStakingAbi, nft.nftStaking);
        // const annualReward = await nftStakingContract.methods.annualReward().call();
        const annualReward = nft.liqRewards
                ? makeBNumber(nft.liqRewards, decimals)
                : await nftStakingContract.methods.annualReward().call();
        const totalReward = parseBNumber(annualReward, decimals);
        setLiqRewards(totalReward);
        const totalRewardPrice = totalReward * liqPrice;
        const anualPercentReward = (totalRewardPrice) * 100 / sumPrice;
        const anualPercentYield = (1 + (anualPercentReward / 100) / COMPOUNDING_PERIOD) ** COMPOUNDING_PERIOD - 1;
        setApy(readableNumber(anualPercentYield * 100));
    }

    const handleDepositeNFT = async () => {
        if (initLoading) return;

        try {
            setLoadingFarm(true);
            await stakeContract.methods.deposit(minTokenID, userAccount).send({ 
                from: userAccount, 
                maxPriorityFeePerGas: null,
                maxFeePerGas: null,
              });
            dispatch(actions.changeRefresh());
            await getContractInfo();
            setApproved(true);
            setLoadingFarm(false);
            setDeposited(true);
        } catch (err) {
            console.log(err);
            setLoadingFarm(false);
        }
    }
 
    return (
        <div className='bg-overlay flex-center'>
            <div className='popup-wrapper'>
                <>
                {deposited ? (
                    // Deposited Success Popup
                    <div className='popup-box'>
                        <div className='card-header flex-between'>
                            <h4 style={{fontWeight: 500}}>
                                Deposit Successful
                            </h4>
                            <IconButton
                                aria-label="close"
                                onClick={() => setOpenPopup(false)} 
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div className='card-body'>
                            <div className='deposit-success'>
                                <div><img src={DepositSuccessImage} alt="" /></div>
                                <p style={{paddingTop: 35, paddingBottom: 28, color: '#112455', textAlign: 'center'}}>Over one year, you will earn a guaranteed rate of</p>
                                <div className='flex-center'>
                                    <img src={LiqLogo} alt="" style={{width: 37, height: 37,  marginRight: 12}}/>
                                    <p style={{color: "#17E7D6"}}><span>{liqRewards}</span> LIQ</p>
                                    <div className='probox flex-center' style={{margin: '0 12px 0 41px'}}>%</div>
                                    <p style={{color: "#17E7D6", cursor: 'pointer'}} onClick={() => setShowInfo(true)}><span>{apy}%</span> APY*</p>
                                </div>
                                {showInfo && (
                                    <div className='soldout-desc flex-center'>
                                        <div className='probox flex-center' style={{marginRight: 24}}>%</div>
                                        <p style={{fontSize: 15, color: '#112455'}}>The APY changes constantly and depends on the purchase price of the NFT and the current price of LIQ.</p>
                                    </div>
                                )}
                                <div
                                    className='flex-center done'
                                    onClick={() => setOpenPopup(false)}
                                >
                                    <span>Done</span>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    // Deposit Popup
                    <div className='popup-box'>
                        <div className='card-header flex-between'>
                            <h4 style={{fontWeight: 500}}>
                                Deposit Liquidus NFT
                            </h4>
                            <IconButton
                                aria-label="close"
                                onClick={() => setOpenPopup(false)} 
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                        {minTokenID ? (
                            <>
                            <div className='card-body'>
                                <div className='process-bar'>
                                    <span className='circle-badge active-badge' style={{marginLeft: 48}}>1</span>
                                    <hr/>
                                    <span className={`circle-badge ${approved ? 'active-badge' : 'inactive-badge'}`} style={{marginRight: 22}}>2</span>
                                </div>
                                <div className='flex-between'>
                                    <span className='active-label'>Approve Tokens</span>
                                    <span className={approved ? 'active-label' : 'inactive-label'}>Stake NFT</span>
                                </div>
                                <div className='pt-10'>
                                    <div className='flex-center'>
                                        <div className='nft-icon flex-center'>
                                            <img src={nft.smallImage} alt="" />
                                        </div>
                                        <div style={{marginLeft: 16}}>
                                            <div><span className='mint-logo-label text-primary text-bold'>LIQNFT</span></div>
                                            <div className='text-bold'>
                                                <span style={{fontSize: 14, color: '#9AA6CF'}}>TOKEN ID: </span>
                                                <span style={{fontSize: 14, color: '#17E7D6'}}>{minTokenID}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex-center' style={{paddingTop: 73}}>
                                        {approved ? (
                                            <div
                                                className='btn-stake btn-confirm flex-center'
                                                style={{width: 268}}
                                                onClick={() => handleDepositeNFT()}
                                            >
                                                {loadingFarm ? (
                                                    <Spinner/>
                                                ) : (
                                                    <span>Deposit</span>
                                                )}
                                            </div>
                                        ) : (
                                            <div
                                                className='btn-stake btn-confirm flex-center'
                                                style={{width: 268}}
                                                onClick={() => handleApprovedNFT()}
                                            >
                                                {loadingApprove ? (
                                                    <Spinner/>
                                                ) : (
                                                    <span>Approve LIQNFT</span>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='card-footer flex-center text-semibold'>
                                <p style={{fontSize: 14, opacity: 0.8}}>Please confirm the transaction in your wallet.</p>
                            </div>
                            </>
                        ) : (
                            <div>
                                {userAccount ? (
                                    <h3 className='mint-logo-label text-primary' style={{padding: '120px 0 30px 0', textAlign: 'center'}}>
                                        No NFT found in your wallet
                                    </h3>
                                ) : (
                                    <h3 className='mint-logo-label text-secondary' style={{padding: '120px 0 30px 0', textAlign: 'center'}}>
                                        Please connect your wallet
                                    </h3>
                                )}
                                
                                {!userAccount && (
                                    <div className='flex-center'>
                                        <button className='btn-round text-btn' onClick={() => {
                                            // setOpenPopup(false);
                                            setOpenWalletPopup(true);
                                        }}>
                                            Connect Wallet
                                        </button>
                                    </div>
                                )}
                                <div style={{textAlign: 'center'}}>
                                    <button
                                        className='btn-stake btn-cancel'
                                        style={{margin: '79px 0', width: '268px'}}
                                        onClick={() => setOpenPopup(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                </>
            </div>
        </div>
    )
}