import React from "react";
import CloseIcon from '@mui/icons-material/Close';

export default function Spinner(props) {
    const { setLoading, color } = props;

    if (props.shape === 'ellipsis') {
        return (
            <div className={"popup-container"}>
                <div className="lds-ellipsis">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className='close-btn' onClick={() => setLoading(false)}>
                    <CloseIcon style={{color: '#ffffff'}}/>
                </div>
            </div>
        )
    } else if (props.shape === "ring") {
        return (
            <div className={"spinner-container"}>
                <div className={"lds-ring"}>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        )
    } else if (props.shape === "ripple") {
        return (
            <div className={"spinner-container"}>
                <div className="lds-ripple">
                    <div></div>
                    <div></div>
                </div>
            </div>
        )
    } else if (props.shape === "dual-ring") {
        return (
            <div className={"spinner-container"}>
                <div className="lds-dual-ring"></div>
            </div>
        )
    } else if (color === 'primary') {
        return (
            <div className="lds-ellipsis primary-color">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        )
    } else {
        return (
            <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        )
    }
}
