import { combineReducers } from 'redux';
import * as common from './common.redux';
import * as wallet from './wallet.redux';

const rootReducer = combineReducers({
    common: common.reducer,
    wallet: wallet.reducer,
});

export default rootReducer;
