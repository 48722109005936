import { LoadingButton } from "@mui/lab"

export const ButtonNormal = ({width, loading, label, onClick}) => {
  return (
    <LoadingButton
      onClick={onClick}
      loading={loading}
      sx={{
        width: width,
        fontFamily: "Montserrat",
        fontSize: {xs: "14px", md: "15px"},
        fontWeight: 600,
        color: "#112455",
        lineHeight: "20px",
        backgroundColor: "#17E7D6",
        border: "none",
        borderRadius: "12px",
        padding: "10px 16px",
        textTransform: "none",
      }}
    >
      {label}
    </LoadingButton>
  );
};
