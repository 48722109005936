// wallet-defiwallet.ts
// import { ethers } from "ethers"; // npm install ethers
import Web3 from "web3";

// This is the SDK provided by Crypto.com DeFi Wallet
import { DeFiWeb3Connector } from "deficonnect"; // npm install deficonnect

import { RPC } from "../abis/constants";
import { getDefaultWallet } from "../helper/connect-wallet";
// import * as utils from "../services/utils";

// Main login flow for Crypto.com DeFi Wallet with Wallet Extension
// The connector must be activated, then it exposes a provider
// that is used by the ethers Web3Provider constructor.

export const connect = async () => {
  const defaultWallet = getDefaultWallet();

  try {
    const net_id = localStorage.getItem('netId');
    const netId = parseInt(net_id);

    const connector = new DeFiWeb3Connector({
      supportedChainIds: [netId],
      rpc: {[netId]: RPC[netId]['url']},
      pollingInterval: 15000,
    });
    await connector.activate();
    const provider = await connector.getProvider();
    const web3 = new Web3(provider);
    localStorage.setItem('walletType', 'defi-wallet');

    if (!(parseInt(provider.chainId) === netId)) {
      window.alert(
        "Switch your Wallet to blockchain network " + netId
      );
    }
    // Subscribe to events that reload the app
    connector.on("session_update", () => {
      window.location.reload();
    });
    connector.on("Web3ReactDeactivate", () => {
      window.location.reload();
    });
    connector.on("Web3ReactUpdate", () => {
      window.location.reload();
    });

    return {
      ...defaultWallet,
      walletProviderName: "defi-wallet",
      address: (await web3.eth.getAccounts())[0],
      wcProvider: provider,
      wcConnector: connector,
      web3: web3,
      connected: true,
      // networkId: parseInt(provider.chainId),
      networkId: netId,
    };
  } catch (e) {
    console.log(e);
    return defaultWallet;
  }
};
